<template>
    <section class="section-container">
        <div class="forget-box">
            <div class="passpost-title">重置密码</div>
            <div class="passport-input passport-bottom" :class="{warp: mobileWarp}">
                <foreign-mobile @countryOrRegionCode="handleCountryOrRegionCode" />
                <input
                    v-model.trim="mobile"
                    type="tel"
                    class="input-field input-field__noicon input-mobile"
                    :maxlength="mobileMaxLength"
                    placeholder="请输入您的手机号"
                    data-cy="forget-pwd-mobile"
                    @input="onMobileChangeHandler"
                    @blur="onMobileBlurHandler"
                >
                <div v-if="mobileWarp" class="input-warp">
                    {{mobileWarp}}
                </div>
            </div>
            <div class="passport-compose passport-bottom">
                <div
                    class="passport-input passport-input__passcode"
                    :class="{warp: passcodeWarp}"
                >
                    <input
                        v-model.trim="passcode"
                        :readonly="readonlyInput"
                        autocomplete="”off”"
                        class="input-field input-field__noicon"
                        type="text"
                        placeholder="请输入您的验证码"
                        maxlength="6"
                        data-cy="forget-pwd-sms-input"
                        @input="onpPasscodeChangeHandler"
                        @blur="onPasscodeBlurHandler"
                    >
                    <div v-if="passcodeWarp || sendPasscode" class="input-warp">
                        {{passcodeWarp || sendPasscode}}
                    </div>
                </div>
                <div class="passport-code">
                    <button
                        type="button"
                        class="passport-code-btn"
                        data-cy="forget-pwd-get-sms"
                        :class="{active:mobile && !count && !mobileWarp && validateMobile(mobile)}"
                        :disabled="!mobile || count != 0 || !validateMobile(mobile)"
                        @click="onGetVerificationCodeHandler"
                    >
                        <span class="passport-code-btn__text">
                            {{codeBtnText}}</span>
                    </button>
                    <voice-code
                        v-if="mobile && showVoiceCode"
                        :type="2"
                        :mobile="mobile"
                    />
                </div>
            </div>
            <div
                class="passport-input"
            >
                <input
                    v-model.trim="password"
                    autocomplete="new-password"
                    type="password"
                    placeholder="8-16位密码，区分大小写"
                    maxlength="16"
                    class="input-field input-field__noicon"
                    data-cy="forget-pwd-pwd-input"
                    @input="onPasswordChangeHandler"
                >
            </div>
            <div v-if="passwordHint" class="password-hint" :class="{'password-warn': passwordWarn}">
                {{passwordHint}}
            </div>
            <div class="forget-box__hint">
                <div v-if="passportWarp" class="hint-text">
                    {{passportWarp}}
                </div>
            </div>
            <button
                type="button"
                class="passport-btn"
                data-cy="forget-pwd-login-btn"
                :disabled="isFetching || isDisabled"
                :class="{is_loading: isFetching, 'is-disabled': isDisabled}"
                @click="onUserForgetHandler"
            >
                <span class="passport-btn__text">{{isFetching ? '重置中...' : '完成并登录'}}</span>
            </button>
        </div>
        <div id="captcha"></div>
        <modal
            name="success-reset"
            transition="nice-modal-fade"
            width="340"
            height="auto"
        >
            <div class="common-popup-modal">
                <div class="popup__body">
                    <div class="modal__text">重置成功</div>
                </div>
            </div>
        </modal>
    </section>
</template>
<script>
import md5 from 'md5';
import api from '~/api';
import qs from 'qs';
import VoiceCode from '~/components/common/VoiceCode';
import axios from 'axios';
import {captchaId} from '@base/util/common.js';
import rsaEncrypt from '@base/util/encrypt';
import {validateInput, validatePasswordLength, validatePassword} from '@base/util/password';
import getPasscodeOptions from '@base/util/protector';
import ForeignMobile from '@business/components/login/ForeignMobile';
import CountryRegionSelect from '@base/mixins/countryRegionSelect';
import {LOGIN_NOREDIRECT_PATH_NAME} from '@base/constants/common';
import PostMessage from '@base/mixins/postMessage';


/* global gio */

export default {
    name: 'Register',
    components: {
        VoiceCode,
        ForeignMobile
    },
    mixins: [CountryRegionSelect, PostMessage],
    data() {
        return {
            mobile: '',
            mobileWarp: '',
            passcode: '',
            passcodeWarp: false,
            sendPasscode: '',
            password: '',
            passportWarp: '',
            isFetching: false,
            codeBtnText: '获取验证码',
            count: 0,
            timer: null,
            showVoiceCode: false,
            figureTime: 35,
            readonlyInput: true
        };
    },
    computed: {
        isDisabled() {
            return !this.validateMobile(this.mobile)
                || !validatePasswordLength(this.password)
                || !validatePassword(this.password)
                || !this.validatePasscode(this.passcode);
        },
        passwordHint() {
            if (!this.password) {
                return '请设置8-16位，大小写字母、数字和符号的三种以上组合，不能与旧密码相同';
            }
            if (!validatePasswordLength(this.password)) {
                return '密码长度需在8-16位字符之间';
            }
            if (!validatePassword(this.password)) {
                return '密码需要包含大写字母、小写字母、数字和符号的三种以上组合';
            }
            return '';
        },
        passwordWarn() {
            if (!this.password) {
                return false;
            }
            return !validatePasswordLength(this.password) || !validatePassword(this.password);
        }
    },
    methods: {
        onMobileBlurHandler() {
            if (!this.mobile.trim()) {
                this.mobileWarp = '请输入手机号';
                return;
            }
            if (!this.validateMobile(this.mobile)) {
                this.mobileWarp = '请输入正确手机号';
            }
        },
        onMobileChangeHandler() {
            if (!this.mobile.trim()) {
                this.mobileWarp = '';
            }
            const pattern = /^\d+$/g;
            if (this.mobile.trim() && !pattern.test(this.mobile)) {
                this.mobileWarp = '请输入正确手机号';
            }
            if (this.validateMobile(this.mobile)) {
                this.mobileWarp = '';
            }
        },
        onPasscodeBlurHandler() {
            // this.readonlyInput= true;
            if (!this.passcode.trim()) {
                this.passcodeWarp = '请输入验证码';
                return;
            }
            if (this.passcode.trim().length < 4 || this.passcode.trim().length === 5) {
                this.passcodeWarp = '请输入正确验证码';
            }
            else {
                this.passcodeWarp = '';
            }
        },
        onpPasscodeChangeHandler() {
            if (this.passcode.trim()) {
                this.passcodeWarp = false;
            }
            this.passcode = this.passcode.replace(/\D+/, '');
        },
        onPasswordChangeHandler(e) {
            while (this.password && !validateInput(this.password)) {
                this.password = this.password.slice(0, this.password.length - 1);
            }
        },
        onGetSlidePassCode() {
            // type: 0注册 1登录 2忘记密码 12绑定手机
            const params = {
                type: 2,
                mobile: this.mobileFormated(),
                captchaMode: 'NETEASE',
                captchaGuid: captchaId
            };
            let captchaIns;
            const that = this;
            initNECaptcha({
                element: '#captcha',
                captchaId,
                mode: 'popup',
                width: '500px',
                onVerify(err, data) {
                    // 用户验证码验证成功后，进行实际的提交行为
                    if (data) {
                        params.captcha = data.validate;
                        that.getVerificationCode(params);
                    }
                }
            }, instance => {
                // 初始化成功后得到验证实例instance，可以调用实例的方法
                captchaIns = instance;
                captchaIns && captchaIns.popUp();
            }, function (err) {
                // 初始化失败后触发该函数，err对象描述当前错误信息
            });
        },
        onGetVerificationCodeHandler() {
            this.readonlyInput = false;
            this.passportWarp = '';
            const params = {
                mobile: this.mobileFormated()
            };
            this.getVerificationCode(params);
        },
        getVerificationCode(payload) {
            if (this.passportWarp.trim()) {
                this.passportWarp = '';
            }
            const params = {type: 2, ...payload};
            this.$axios.$post(api.user.sendPasscode, qs.stringify({
                ...params,
                ...getPasscodeOptions()
            })).then(res => {
                const {code, msg} = res;
                switch (code) {
                    case 0:
                        this.onCountdownHandler();
                        this.sendPasscode = '验证码已发送到您的手机';
                        break;
                    // case 12:
                    // this.$emit('hideForgetBox')
                    // this.$emit("showRegPop")
                    // this.$modal.show('12-login-popup')
                    // break;
                    case 130:
                        // this.$modal.show('captcha-code-modal', {
                        //     captcha: res,
                        //     mobile: this.mobile
                        // })
                        this.onGetSlidePassCode();
                        break;
                    default:
                        this.passportWarp = msg;
                        break;
                }
            });
        },
        onCountdownHandler() {
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.codeBtnText = `${this.count}秒后重发`;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                        if (this.count === this.figureTime) {
                            this.showVoiceCode = true;
                        }
                        this.codeBtnText = `${this.count}秒后重发`;
                    }
                    else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.codeBtnText = '获取验证码';
                    }
                }, 1000);
            }
        },
        validatePasscode(passcode) {
            return !(passcode.length < 4 || passcode.length === 5);
        },
        onUserForgetHandler() {
            this.isFetching = true;
            const params = {
                mobile: this.mobileFormated(),
                newPassword: md5(this.password),
                newPasswordOrigin: rsaEncrypt(this.password),
                isEncrypted: true,
                passcode: this.passcode,
                resetPassword: false
            };
            axios.post(api.user.resetPassword, qs.stringify(params)).then(res => {
                const {code, msg, data = {}} = res.data;
                this.isFetching = false;
                if (code === 0) {
                    this.$store.dispatch('GET_ENTRANCE');
                    this.$store.commit('TOKEN', data.sessionId);
                    this.$store.commit('USER_INFO', data);
                    this.$modal.show('success-reset');

                    // growingio 采集登录用户信息
                    window.gio && gio('setuserId', data.userId);

                    setTimeout(() => {
                        this.$modal.hide('success-reset');
                    }, 2000);
                    this.postIframeData(true, true);
                    this.$store.commit('SET_ISSHOWFORGET', false);
                    if (!LOGIN_NOREDIRECT_PATH_NAME.includes(this.$route.name)) {
                        this.$router.replace('/refresh');
                    }
                }
                else {
                    this.passportWarp = msg;
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
.changing-over {
    position: relative;
    padding: 14px 0 0;
    height: 16px;

    a {
        font-size: 14px;
        color: #f63232;
        transition: all .3s;

        &:hover {
            font-weight: 700;
        }
    }
}

.forget-box {
    position: relative;
    background-color: #fff;
    height: 458px;
    border-radius: 8px;
    margin: 0 auto;
    padding: 0 35px;

    .passport-input {
        position: relative;
        background: #fff;
        border: 1px solid #ebebeb;
        border-radius: 4px;

        &.passport-bottom {
            margin-bottom: 30px;
        }

        &__passcode {
            width: 312px;

            .input-field {
                width: 270px !important;
            }
        }

        .input-warp {
            position: absolute;
            width: 100%;
            font-size: 14px;
            height: 28px;
            line-height: 28px;
            bottom: -28px;
        }

        .input-field {
            line-height: 20px;
            height: 20px;
            border: 0;
            outline: none;
            padding: 16px 0 16px 55px;
            width: 387px;
            float: none;
            overflow: hidden;
            font-size: 14px;
            color: #32404b;
            border-radius: 4px;

            &__noicon {
                padding: 16px 18px;
                width: 406px;
            }

        }

        /deep/ .country-select {
            margin-left: 18px;
        }

        .input-mobile {
            width: 240px;
            padding-left: 0;
        }
    }

    .passport-compose {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .password-hint {
        margin: 5px 0;
    }

    .passport-code-btn {
        width: 112px;
    }

    .passport-btn {
        margin-top: 25px;
        margin-bottom: 50px;
        margin-left: 0 !important;
        width: 100% !important;

        &.is-disabled {
            color: #fff !important;
            background-color: #eee !important;
        }
    }

    .password-warn {
        color: #f00 !important;
    }

    .success-btn {
        position: relative;
        display: block;
        text-align: center;
        width: 100%;
        height: 44px;
        line-height: 44px;
        background: #f63232;
        border-radius: 4px;
        font-size: 24px;
        color: #fff;

        &:hover {
            background-color: #ff4c52;
        }

        &:active {
            background-color: #e00;
        }
    }

    &__hint {
        margin: 5px 0;

        .hint-text {
            color: #f63232;
        }
    }
}
</style>
