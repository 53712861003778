<template>
    <div>
        <modal
            name="stater-common-popup"
            transition="nice-modal-fade"
            width="340"
            height="auto"
            @before-open="beforeOpen"
        >
            <div class="common-popup-modal">
                <div class="popup__head">
                    提示
                    <i class="icon iconfont icon-guanbi" @click="$modal.hide('stater-common-popup')"></i>
                </div>
                <div class="popup__body">
                    <div class="modal__text">{{error_info}}</div>
                    <div class="modal-btn" @click="$modal.hide('stater-common-popup')">确定</div>
                </div>
            </div>
        </modal>
        <modal name="stater-login-popup" transition="nice-modal-fade" width="360" height="auto">
            <div class="common-popup-modal">
                <div class="popup__head">
                    提示
                    <i class="icon iconfont icon-guanbi" @click="onCloseAgainLoginHandler"></i>
                </div>
                <div class="popup__body">
                    <div class="modal__text loginText">{{res.error_info}}</div>
                    <div v-if="res.status == 16" class="modal-btn" @click="onAgainLoginHandler">重新登录</div>
                    <div v-if="res.status == 29" class="modal__btns">
                        <div class="modal__btn_default" @click="onForgetHandler">修改密码</div>
                        <div class="modal__btn_default modal__btn_primary" @click="onAgainLoginHandler">重新登录</div>
                    </div>
                </div>
            </div>
        </modal>
        <modal
            name="stater-loginBox-popup"
            transition="nice-modal-fade"
            width="536"
            height="auto"
            style="z-index: 990;"
            :click-to-close="false"
        >
            <div class="login-popup-modal">
                <i class="icon iconfont icon-guanbi" @click="onCloseLoginHandler"></i>
                <login-box
                    :auto-play="autoPlay"
                    :order="order"
                    @hideLoginBox="onCloseLoginHandler"
                    @showRegPop="showRegPop"
                />
            </div>
        </modal>
        <!-- <modal
            name="stater-registerBox-popup"
            transition="nice-modal-fade"
            width="536"
            height="auto"
            :click-to-close="false"
        >
            <div class="login-popup-modal">
                <i class="icon iconfont icon-guanbi" @click="onCloseRegisterHandler"></i>
                <register-box @hideRegisterBox="onCloseRegisterHandler" @showLoginPop="showLoginPop" />
            </div>
        </modal> -->
        <modal
            name="stater-forgetBox-popup"
            transition="nice-modal-fade"
            width="536"
            height="auto"
            :click-to-close="false"
        >
            <div class="login-popup-modal">
                <i class="icon iconfont icon-guanbi" @click="onCloseForgetHandler"></i>
                <forget-box @hideForgetBox="onCloseForgetHandler" @showRegPop="showRegPop" />
            </div>
        </modal>
        <modal
            name="12-login-popup"
            transition="nice-modal-fade"
            width="340"
            height="auto"
            :click-to-close="false"
        >
            <div class="common-popup-modal">
                <div class="popup__head">
                    提示
                    <i class="icon iconfont icon-guanbi" @click="$modal.hide('12-login-popup')"></i>
                </div>
                <div class="popup__body">
                    <div class="modal__text">手机号还未注册过,是否立即注册?</div>
                    <div class="modal__btns">
                        <div class="modal__btn_default" @click="$modal.hide('12-login-popup')">取消</div>
                        <div class="modal__btn_default modal__btn_primary" @click="onHintRegisterHandler">注册</div>
                    </div>
                </div>
            </div>
        </modal>
        <modal
            name="11-login-popup"
            transition="nice-modal-fade"
            width="340"
            height="auto"
            :click-to-close="false"
        >
            <div class="common-popup-modal">
                <div class="popup__head">
                    提示
                    <i class="icon iconfont icon-guanbi" @click="$modal.hide('11-login-popup')"></i>
                </div>
                <div class="popup__body">
                    <div class="modal__text">该手机号已经注册过,是否前去登录？</div>
                    <div class="modal__btns">
                        <div class="modal__btn_default" @click="$modal.hide('11-login-popup')">取消</div>
                        <div class="modal__btn_default modal__btn_primary" @click="onHintLoginHandler">登录</div>
                    </div>
                </div>
            </div>
        </modal>
        <modal
            name="notsold-common-popup"
            transition="nice-modal-fade"
            width="340"
            height="auto"
            @before-open="beforeOpen"
        >
            <div class="common-popup-modal">
                <div class="popup__head">
                    提示
                    <i class="icon iconfont icon-guanbi" @click="$modal.hide('notsold-common-popup')"></i>
                </div>
                <div class="popup__body" style="text-align: left;">
                    <div class="modal__text">{{error_info}}</div>
                    <div class="modal-btn" @click="onHideNotSoldPop()">确定</div>
                </div>
            </div>
        </modal>
        <!-- <notice-modal /> -->
        <!-- <qing-lang-modal /> -->
    </div>
</template>

<script>
import $Bus from '@base/plugins/EventBus';
import LoginBox from '@business/components/login/LoginBox';
import ForgetBox from '@business/components/login/ForgetBox';
// import NoticeModal from '~/components/NoticeModal';
// import {STORAGE__NOTICE} from '@/constants/storage-types';
// import StorageUtil from '@/util/storage';
// import QingLangModal from '~/components/QingLangModal';
// import {STORAGE__QING_LANG} from '@/constants/storage-types';
// import StorageUtil from '@/util/storage';
import {mapState} from 'vuex';
import PostMessage from '@base/mixins/postMessage';


export default {
    name: 'Stater',
    components: {
        LoginBox,
        ForgetBox,
        // NoticeModal
        // QingLangModal
    },
    mixins: [PostMessage],
    data() {
        return {
            error_info: '',
            res: {},
            autoPlay: '',
            order: ''
        };
    },
    computed: {
        ...mapState({
            isShowLogin: state => state.isShowLogin,
            isShowRegister: state => state.isShowRegister,
            isShowForget: state => state.isShowForget
        })
    },
    watch: {
        isShowLogin(val) {
            if (val) {
                this.$modal.show('stater-loginBox-popup');
            }
            else {
                this.$modal.hide('stater-loginBox-popup');
            }
        },
        isShowRegister(val) {
            if (val) {
                this.$modal.show('stater-registerBox-popup');
            }
            else {
                this.$modal.hide('stater-registerBox-popup');
            }
        },
        isShowForget(val) {
            if (val) {
                this.$modal.show('stater-forgetBox-popup');
            }
            else {
                this.$modal.hide('stater-forgetBox-popup');
            }
        }
    },
    mounted() {
        // 清朗
        // this.$nextTick(() => {
        //     if (!StorageUtil.get(STORAGE__QING_LANG)) {
        //         this.$modal.show('qing-lang-modal');
        //     }
        // });

        // this.$nextTick(() => {
        //     if (!StorageUtil.get(STORAGE__NOTICE)) {
        //         this.$modal.show('notice-modal');
        //     }
        // });

        const that = this;
        $Bus.$on('excHandler', function (data) {
            let {
                code,
                status,
            } = data;

            // 资讯详情，接口不会反悔code or status，只会返回html结构
            if (data.indexOf?.('article') > -1 || data.indexOf?.('div') > -1) {
                code = 0;
            }

            code = typeof code === 'undefined' ? status : code;

            if (code !== 0) {
                that.res = data;
            }
            this.error_info = data.error_info || data.msg || '服务异常，请稍后重试';
            switch (code) {
                case 0:
                case 11:
                case 12:
                case 17:
                case 25:
                case 36:
                case 130:
                    // 客服请求成功返回的code码：10240000
                case 10240000:
                    break;
                // 后端常见的异常返回状态
                case 1:
                    that.$modal.show('stater-common-popup', {
                        error_info: this.error_info
                    });
                    break;
                case 16:
                case 29:
                    that.$store.commit('CLEAR_TOKEN');
                    that.$modal.show('stater-login-popup');
                    that.$modal.hide('stater-loginBox-popup');
                    that.$store.commit('SET_ISSHOWLOGIN', false);
                    break;
                // 已存在雷同订单
                case 403:
                    that.$modal.show('stater-common-popup', {
                        error_info: '该课程仅限未购买过2019春季二年级语文课程学员购买'
                    });
                    break;
                // 已存在雷同订单
                case 420:
                    that.$modal.show('stater-common-popup', {
                        error_info: this.error_info
                    });
                    break;
                // 已购买
                case 440:
                    that.$modal.show('stater-common-popup', {
                        error_info: this.error_info
                    });
                    break;
                case 445:
                    //  that.$modal.show('apply-success-popup')
                    break;
                // 不可插班学习
                case 60002:
                    that.$modal.show('notsold-common-popup', {
                        error_info: this.error_info
                    });
                    break;
                case 60003:
                case 62013:
                    break;
                case 9000:
                    that.$router.push('/busy');
                    break;
                case 9001:
                    // 9001 题库页，用户今日查看题目已到限制次数
                    break;
                default:
                    that.$modal.show('stater-common-popup', {
                        error_info: this.error_info
                    });
                    break;
            }
        });
        if (this.isShowLogin) {
            this.$modal.show('stater-loginBox-popup');
        }

        /* 监听自动播放和自动预约 */
        $Bus.$on('autoPlay', data => {
            that.autoPlay = 1;
        });
        $Bus.$on('order', data => {
            that.order = 1;
        });
    },
    methods: {
        beforeOpen(e) {
            this.error_info = e.params.error_info; 
        },
        // 12-box
        showRegPop() {
            this.$modal.show('12-login-popup');
        },
        // 11-box
        showLoginPop() {
            this.$modal.show('11-login-popup');
        },
        onHideNotSoldPop() {
            this.$modal.hide('notsold-common-popup');
            this.$router.push('/refresh');
        },
        onHintRegisterHandler() {
            this.$modal.hide('12-login-popup');
            this.$modal.show('stater-registerBox-popup');
        },
        onHintLoginHandler() {
            this.$modal.hide('11-login-popup');
            this.$modal.show('stater-loginBox-popup');
        },
        onCloseLoginHandler() {
            this.$store.commit('SET_ISSHOWLOGIN', false);
            this.$modal.hide('stater-loginBox-popup');
        },
        // onCloseRegisterHandler() {
        //     this.$modal.hide('stater-registerBox-popup');
        //     this.$store.commit('SET_ISSHOWREGISTER', false);
        // },
        onCloseForgetHandler() {
            this.$modal.hide('stater-forgetBox-popup');
            this.$store.commit('SET_ISSHOWFORGET', false);
            this.postIframeData(true, false);
        },
        onCloseAgainLoginHandler() {
            this.$modal.hide('stater-login-popup');
            window.location.reload();
        },
        onAgainLoginHandler() {
            this.$modal.hide('stater-login-popup');
            this.res = {};
            this.$store.commit('SET_ISSHOWLOGIN', true);
            this.$modal.show('stater-loginBox-popup');
        },
        onForgetHandler() {
            this.$modal.hide('stater-login-popup');
            this.res = {};
            this.$store.commit('SET_ISSHOWFORGET', true);
        }
    }
};
</script>

<style lang="less" scoped>
.login-popup-modal {
    position: relative;

    .icon-guanbi {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 1;
        cursor: pointer;
    }
}
</style>
