/* eslint-disable guard-for-in */
export default () => {
    const timer = setTimeout(() => {
        try {
            // 有效时间 86400000 一天毫秒数
            const VALID_TIME = 86400000;
            for (const [key, value] of Object.entries(localStorage)) {
                if (key.indexOf('source_leads_') !== -1) {
                    const nowDate = new Date().valueOf();
                    const data = JSON.parse(value);
                    const {createTime = 0} = data || {};
                    if (nowDate - Number(createTime) > VALID_TIME) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }
        catch (error) {
            console.error(error);
        }
        clearTimeout(timer);
    }, 0)
};
