<template>
    <div class="side-fixed-menu">
        <a
            class="service"
            href="javascript:;"
            title="在线客服"
            :style="{
                background: serviceUseGif ? `center / contain no-repeat url(${serviceUseGif})` : '#ff443d'
            }"
            @click="onChatHandler"
        >
            <template v-if="!serviceUseGif">
                <svg-icon icon-class="service" class-name="service__icon icon iconfont" />
                <!--- 老师详情页单独加咨询埋点 --->
                <template v-if="isTeacherDetailPage">
                    <div v-clickReport="36085440" class="service__text">咨询</div>
                </template>
                <template v-else>
                    <div v-clickReport="34221297" class="service__text">咨询</div>
                </template>
            </template>
            <div v-if="servicePopoverShow" class="service-popover">
                <div class="service-header" @click.stop="closeServicePopover">
                    <div class="service-time">{{serviceCount}}s自动关闭</div>
                    <div class="service-close-btn"></div>
                </div>
                <div class="service-text">点击咨询，专属老师为您定制学习计划</div>
            </div>
        </a>
        <ul>
            <a
                class="side-sort"
                href="javascript:;"
                title="客服电话"
                :style="{
                    background: phoneUseGif ? `center / contain no-repeat url(${phoneUseGif})` : '#fff'
                }"
                @click="menuClickReport('电话')"
            >
                <template v-if="!phoneUseGif">
                    <svg-icon icon-class="consult" class-name="side-sort__icon icon iconfont" />
                    <div class="side-sort__text">电话</div>
                </template>
                <div class="service__text">
                    <div class="service__phone">{{customServiceNumber}}</div>
                    <div class="service__time">
                        <span>周一至周日</span>
                        <span>9:00-22:00</span>
                    </div>
                </div>
            </a>
            <a
                class="side-sort"
                href="javascript:;"
                title="意见反馈"
                data-habo-params="event_id=34221144"
                :style="{
                    background: feedbackUseGif ? `center / contain no-repeat url(${feedbackUseGif})` : '#fff'
                }"
                @click="gotoSuggest"
            >
                <template v-if="!feedbackUseGif">
                    <svg-icon icon-class="feedback" class-name="side-sort__icon icon iconfont" />
                    <div class="side-sort__text">反馈</div>
                </template>
            </a>
            <a
                class="side-sort"
                title="APP下载"
                :style="{
                    background: downloadUseGif ? `center / contain no-repeat url(${downloadUseGif})` : '#fff'
                }"
                @click="toDownload"
            >
                <template v-if="!downloadUseGif">
                    <svg-icon icon-class="phone" class-name="side-sort__icon icon iconfont" />
                    <div v-clickReport="34221273" class="side-sort__text">下载</div>
                </template>
                <div class="down-app">
                    <div class="wx-code">
                        <img v-lazy="require('~/assets/img/app-down-code-img.png')" alt="下载APP">
                    </div>
                    <div class="text">下载APP</div>
                </div>
            </a>
            <transition name="el-fade-in">
                <a
                    v-if="goTopShow"
                    class="side-sort gotop"
                    href="javascript:;"
                    title="返回顶部"
                    :style="{
                        background: gototopUseGif ? `center / contain no-repeat url(${gototopUseGif})` : '#fff'
                    }"
                    @click="onGotoTop"
                >
                    <template v-if="!gototopUseGif">
                        <svg-icon icon-class="gototop" class-name="side-sort__icon icon iconfont" />
                        <!-- <div class="side-sort__text"></div> -->
                    </template>
                </a>
            </transition>
        </ul>
        <chat :isShowChatPopup.sync="isShowChatPopup" />
    </div>
</template>
<script>
import {mapState} from 'vuex';
import chat from '~/components/common/chat';
import {CUSTOM_SERVICE_NUMBER} from '@base/constants/common';
import {
    EVENT_ID_SERVICE_MODEL_SHOW,
    EVENT_ID_CLOSE_SERVICE_MODEL_CLICK,
    EVENT_ID_MENU_CLICK
} from '@base/constants/homepage';

export default {
    name: 'SideFixedMenu',
    components: {
        chat
    },
    data() {
        return {
            goTopShow: false,
            isShow: 0,
            isTeacherDetailPage: false,
            isScrollEnd: true,
            currentTop: 0,
            timer: null,
            showGiftBox: false,
            data: {},
            isShowChatPopup: false,
            servicePopoverShow: false,
            serviceTimer: null,
            serviceCount: 4
        };
    },
    computed: {
        ...mapState({
            token: state => state.token,
            commonVariable: state => state.commonVariable,
            sideFixedMenu: state => state.sideFixedMenu,
            classify: state => state.classify,
            direction: state => state.direction,
            subSubject: state => state.subSubject
        }),
        customServiceNumber() {
            let {customServiceNumber = '4000910188'} = this.commonVariable;
            if (customServiceNumber?.indexOf('-') === -1) {
                customServiceNumber = customServiceNumber.split('');
                customServiceNumber.splice(3, 0, '-');
                customServiceNumber.splice(8, 0, '-');
                customServiceNumber = customServiceNumber.join('');
            }
            return customServiceNumber || CUSTOM_SERVICE_NUMBER;
        },
        serviceUseGif() {
            return this.sideFixedMenu.service || '';
        },
        phoneUseGif() {
            return this.sideFixedMenu.phone || '';
        },
        feedbackUseGif() {
            return this.sideFixedMenu.feedback || '';
        },
        downloadUseGif() {
            return this.sideFixedMenu.download || '';
        },
        gototopUseGif() {
            return this.sideFixedMenu.gototop || '';
        }
    },
    mounted() {
        this.firstServiceShow();
        // this.$nextTick(function () {
        //     window.addEventListener("scroll", this.handleScroll);
        // });

        // 老师详情页加埋点
        if (window.location.pathname.indexOf('/teacher/detail/') > -1) {
            this.isTeacherDetailPage = true;
        }
        window.addEventListener('scroll', this.handleScroll);
    },
    deactivated() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll(event) {
            const scrollTop
                = window.pageYOffset
                    || document.documentElement.scrollTop
                    || document.body.scrollTop;
            if (scrollTop > 400) {
                this.goTopShow = true;
            }
            else {
                this.goTopShow = false;
            }
            //  新手礼包 -----
            this.currentTop = scrollTop;
            this.isScrollEnd = false;
            this.timer && clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.handleScrollEnd();
            }, 3000);

            //  新手礼包 ------
        },

        /**
         * 监听滚动结束
         */
        handleScrollEnd() {
            const scrollTop
                = window.pageYOffset
                    || document.documentElement.scrollTop
                    || document.body.scrollTop;
            if (scrollTop === this.currentTop) {
                clearTimeout(this.timer);
                this.isScrollEnd = true;
            }
        },
        onChatHandler() {
            this.isShowChatPopup = true;
            this.menuClickReport('咨询');
        },
        onGotoTop(e) {
            document.documentElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            document.body.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        },
        gotoSuggest() {
            if (!this.token) {
                this.$store.commit('SET_ISSHOWLOGIN', true);
                return;
            }
            this.menuClickReport('反馈');
            this.$router.push({path: '/suggest'});
        },
        fixedtoGiftPackage() {
            window.location.href = this.data.fixedEntranceConfig.activityUrl;
        },
        toDownload() {
            this.menuClickReport('下载');
            this.$router.push({path: '/download'});
        },
        // 关闭客服弹窗
        closeServicePopover() {
            this.servicePopoverShow = false;
            this.$habo.haboClickReport(EVENT_ID_CLOSE_SERVICE_MODEL_CLICK);
        },
        // 客服弹窗倒计时
        countDown() {
            // 4s结束后，清楚定时器，关闭弹窗
            if (this.serviceCount === 0) {
                clearTimeout(this.serviceTimer);
                this.servicePopoverShow = false;
                return;
            }
            // 倒计时
            this.serviceTimer = setTimeout(() => {
                this.serviceCount--;
                this.countDown();
            }, 1000);
        },
        // 侧边栏点击上报
        menuClickReport(button_name = '') {
            const {name, params, query} = this.$route;
            const {direction, classify, subSubject} = this;
            const pages = {
                'index': '首页',
                'category*': '选课中心',
                'download': '下载APP',
                'aboutus': '关于高途',
                'searchResult': '搜索结果页',
                'learn-course_id': '课程详情页',
                'information*': '资讯详情页'
            };
            const channel_id = name === 'category*'
                ? +params.subject_id || +subSubject || +classify || +direction || -1
                : '';
            const haboParams = {
                button_name,
                page: pages[name] || '',
                clazz_number: name === 'learn-course_id' ? params.course_id : '',
                search_words: name === 'searchResult' ? query.searchWord : '',
                news_id: name === 'information*' ? params.information_id : '',
                channel_id
            };
            this.$habo.haboClickReport(EVENT_ID_MENU_CLICK, haboParams);
        },
        // 客服弹窗展示逻辑
        firstServiceShow() {
            this.serviceCount = +this.sideFixedMenu.serviceCount || this.serviceCount;
            const isServicePopoverShow = sessionStorage.getItem('servicePopoverShow');
            if (!isServicePopoverShow) {
                this.servicePopoverShow = true;
                sessionStorage.setItem('servicePopoverShow', true);
                this.$habo.haboViewPage(EVENT_ID_SERVICE_MODEL_SHOW);
                this.countDown();
            }
        }
    }
};
</script>
<style lang="less" scoped>
.side-fixed-menu {
    position: fixed;
    bottom: 120px;
    right: 32px;
    height: 325px;
    z-index: 99;
    transition: all .5s;

    .gift-package {
        position: absolute;
        top: -104px;
        right: -17px;
        width: 90px;
        height: 94px;
        background-size: 100% 100%;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    .gift-slidein {
        animation: slidein .5s;
        right: -17px;
    }

    .gift-slideout {
        animation: slideout .5s;
        right: -80px;
    }

    ul {
        list-style: none;
        width: 52px;
        border-radius: 4px;
        border: solid 1px #eee;

        .side-sort {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 52px;
            height: 61px;
            text-align: center;
            color: #32404b;
            transition: all .2s linear;
            background-color: #fff;
            cursor: pointer;

            &:not(:first-child) {
                &::before {
                    content: "";
                    position: absolute;
                    top: -1px;
                    left: 8px;
                    right: 8px;
                    height: 1px;
                    background-color: #f5f5f5;
                }
            }

            &:first-child {
                border-radius: 4px 4px 0 0;
            }

            &:last-child {
                border-radius: 0 0 4px 4px;
            }

            .iconfont {
                font-size: 26px;
            }

            .service__text {
                position: absolute;
                top: 0;
                left: -100px;
                padding: 8px;
                background-color: #fff;
                box-shadow: -3px 2px 8px 0 #0802020f;
                border-radius: 4px;
                border: 1px solid #EBEBEB;
                text-align: center;
                opacity: 0;
                filter: alpha(opacity=0);
                transition: .3s all ease-out;
                pointer-events: none;
                display: inline-block;

                .service__phone {
                    font-size: 18px;
                    font-weight: 600;
                    color: #333;
                    line-height: 26px;
                    text-align: center;
                }

                .service__time {
                    height: 14px;
                    font-size: 10px;
                    margin-top: 5px;
                    color: #8b8fa1;
                    line-height: 14px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    white-space: nowrap;

                    span:first-child {
                        margin-right: 3px;
                    }
                }
            }

            .down-app {
                position: absolute;
                top: 0;
                left: -100px;
                padding: 17px 17px 0;
                width: 94px;
                background-color: #fff;
                box-shadow: -3px 2px 8px 0 #0802020f;
                border: solid 1px #ebebeb;
                text-align: center;
                opacity: 0;
                filter: alpha(opacity=0);
                transition: .3s all ease-out;
                pointer-events: none;

                .wx-code {
                    width: 94px;
                    height: 94px;

                    img {
                        width: 100%;
                        height: auto;
                        vertical-align: top;
                    }
                }

                .text {
                    padding: 11px 0 14px;
                    font-size: 12px;
                    color: #8b8fa1;
                }
            }

            &:hover {
                color: #ff443d;
                background-color: #b5b5b51a;

                .down-app {
                    left: -138px;
                    opacity: 1;
                    filter: alpha(opacity=100);
                }

                .service__text {
                    transform: translateX(-53px);
                    opacity: 1;
                    filter: alpha(opacity=100);
                }
            }

            &__icon {
                width: 100%;
                height: 25px;
                margin-bottom: 6px;
                box-sizing: border-box;
            }

            &__text {
                line-height: 16px;
                font-size: 12px;
            }
        }

        .gotop {
            height: 34px;
        }
    }

    .service {
        background-color: #ff443d;
        border-radius: 4px;
        color: #fff;
        margin-bottom: 12px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 52px;
        height: 61px;
        text-align: center;
        transition: all .2s linear;

        &:hover {
            &::after {
                content: "";
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                width: 56px;
                height: 61px;
                z-index: 100;
                background-color: rgba(255, 255, 255, .1);
            }
        }

        .iconfont {
            font-size: 26px;
        }

        &__icon {
            width: 100%;
            height: 25px;
            margin-bottom: 6px;
            box-sizing: border-box;
        }

        &-popover {
            background: url(https://gtoss.gsxcdn.com/1956128914_i3c4m45t.png);
            background-size: contain;
            background-repeat: no-repeat;
            width: 192px;
            height: 85px;
            position: absolute;
            top: 0;
            right: 64px;
            box-shadow: -3px 2px 8px 0 #0802020f;
        }

        &-text {
            width: 109px;
            height: 36px;
            color: #414455;
            position: absolute;
            bottom: 21px;
            right: 20px;
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
            text-align: left;
        }

        &-header {
            position: absolute;
            top: 0;
            right: -5px;
            min-width: 62px;
            height: 18px;
            background-color: rgba(97, 97, 105, .6);
            border-radius: 30px;
            padding: 0 29px 0 8px;
        }

        &-time {
            color: #fff;
            line-height: 18px;
            font-size: 11px;
            font-weight: 400;
        }

        &-close-btn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            position: absolute;
            top: -5px;
            right: -3px;
            background: url(https://gtoss.gsxcdn.com/1956115877_kfn1d433.png);
            background-size: contain;
            background-repeat: no-repeat;

            &:hover {
                opacity: .7;
            }
        }
    }
}
@media screen and (max-width: 1280px) {
    .side-fixed-menu {
       right: 0;
    }
}
</style>
