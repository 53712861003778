var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"side-fixed-menu"},[_c('a',{staticClass:"service",style:({
            background: _vm.serviceUseGif ? ("center / contain no-repeat url(" + _vm.serviceUseGif + ")") : '#ff443d'
        }),attrs:{"href":"javascript:;","title":"在线客服"},on:{"click":_vm.onChatHandler}},[(!_vm.serviceUseGif)?[_c('svg-icon',{attrs:{"icon-class":"service","class-name":"service__icon icon iconfont"}}),_vm._v(" "),(_vm.isTeacherDetailPage)?[_c('div',{directives:[{name:"clickReport",rawName:"v-clickReport",value:(36085440),expression:"36085440"}],staticClass:"service__text"},[_vm._v("咨询")])]:[_c('div',{directives:[{name:"clickReport",rawName:"v-clickReport",value:(34221297),expression:"34221297"}],staticClass:"service__text"},[_vm._v("咨询")])]]:_vm._e(),_vm._v(" "),(_vm.servicePopoverShow)?_c('div',{staticClass:"service-popover"},[_c('div',{staticClass:"service-header",on:{"click":function($event){$event.stopPropagation();return _vm.closeServicePopover($event)}}},[_c('div',{staticClass:"service-time"},[_vm._v(_vm._s(_vm.serviceCount)+"s自动关闭")]),_vm._v(" "),_c('div',{staticClass:"service-close-btn"})]),_vm._v(" "),_c('div',{staticClass:"service-text"},[_vm._v("点击咨询，专属老师为您定制学习计划")])]):_vm._e()],2),_vm._v(" "),_c('ul',[_c('a',{staticClass:"side-sort",style:({
                background: _vm.phoneUseGif ? ("center / contain no-repeat url(" + _vm.phoneUseGif + ")") : '#fff'
            }),attrs:{"href":"javascript:;","title":"客服电话"},on:{"click":function($event){return _vm.menuClickReport('电话')}}},[(!_vm.phoneUseGif)?[_c('svg-icon',{attrs:{"icon-class":"consult","class-name":"side-sort__icon icon iconfont"}}),_vm._v(" "),_c('div',{staticClass:"side-sort__text"},[_vm._v("电话")])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"service__text"},[_c('div',{staticClass:"service__phone"},[_vm._v(_vm._s(_vm.customServiceNumber))]),_vm._v(" "),_vm._m(0)])],2),_vm._v(" "),_c('a',{staticClass:"side-sort",style:({
                background: _vm.feedbackUseGif ? ("center / contain no-repeat url(" + _vm.feedbackUseGif + ")") : '#fff'
            }),attrs:{"href":"javascript:;","title":"意见反馈","data-habo-params":"event_id=34221144"},on:{"click":_vm.gotoSuggest}},[(!_vm.feedbackUseGif)?[_c('svg-icon',{attrs:{"icon-class":"feedback","class-name":"side-sort__icon icon iconfont"}}),_vm._v(" "),_c('div',{staticClass:"side-sort__text"},[_vm._v("反馈")])]:_vm._e()],2),_vm._v(" "),_c('a',{staticClass:"side-sort",style:({
                background: _vm.downloadUseGif ? ("center / contain no-repeat url(" + _vm.downloadUseGif + ")") : '#fff'
            }),attrs:{"title":"APP下载"},on:{"click":_vm.toDownload}},[(!_vm.downloadUseGif)?[_c('svg-icon',{attrs:{"icon-class":"phone","class-name":"side-sort__icon icon iconfont"}}),_vm._v(" "),_c('div',{directives:[{name:"clickReport",rawName:"v-clickReport",value:(34221273),expression:"34221273"}],staticClass:"side-sort__text"},[_vm._v("下载")])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"down-app"},[_c('div',{staticClass:"wx-code"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('~/assets/img/app-down-code-img.png')),expression:"require('~/assets/img/app-down-code-img.png')"}],attrs:{"alt":"下载APP"}})]),_vm._v(" "),_c('div',{staticClass:"text"},[_vm._v("下载APP")])])],2),_vm._v(" "),_c('transition',{attrs:{"name":"el-fade-in"}},[(_vm.goTopShow)?_c('a',{staticClass:"side-sort gotop",style:({
                    background: _vm.gototopUseGif ? ("center / contain no-repeat url(" + _vm.gototopUseGif + ")") : '#fff'
                }),attrs:{"href":"javascript:;","title":"返回顶部"},on:{"click":_vm.onGotoTop}},[(!_vm.gototopUseGif)?[_c('svg-icon',{attrs:{"icon-class":"gototop","class-name":"side-sort__icon icon iconfont"}})]:_vm._e()],2):_vm._e()])],1),_vm._v(" "),_c('chat',{attrs:{"isShowChatPopup":_vm.isShowChatPopup},on:{"update:isShowChatPopup":function($event){_vm.isShowChatPopup=$event},"update:is-show-chat-popup":function($event){_vm.isShowChatPopup=$event}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service__time"},[_c('span',[_vm._v("周一至周日")]),_vm._v(" "),_c('span',[_vm._v("9:00-22:00")])])}]

export { render, staticRenderFns }