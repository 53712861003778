<template>
    <div class="page-container">
        <div
            v-if="error.statusCode === 404"
            class="error_content"
        >
            <div class="error__img"><img src="~assets/img/404-bg.png" alt=""></div>
            <nuxt-link to="/">返回首页</nuxt-link>
        </div>
        <div
            v-else
            class="error_content"
        >
            <div class="error__img"><img src="~assets/img/500-bg.png" alt=""></div>
            <nuxt-link to="/">返回首页</nuxt-link>
        </div>
    </div>
</template>
<script>
export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['error']
};
</script>
<style lang="less" scoped>
.page-container {
    position: relative;
    margin: 0 auto;
    width: 610px;
    padding: 120px 0;
}

.error_content {
    .error__img {
        width: 610px;

        img {
            width: 100%;
            height: auto;
            vertical-align: top;
        }
    }

    a {
        position: relative;
        margin: 60px auto 0;
        display: block;
        width: 180px;
        height: 60px;
        border-radius: 4px;
        border: solid 1px #f63232;
        text-align: center;
        line-height: 60px;
        font-size: 24px;
        color: #f63232;
    }
}
</style>
