<template>
    <div v-if="isShowChatPopup" class="chat-popup">
        <div class="head">
            <span>高途</span>
            <span>
                <i class="icon iconfont icon-guanbi" @click="close"></i>
            </span>
        </div>
        <iframe id="iframe" src=""  width="400" height="480"  frameborder="0"></iframe>
    </div>
</template>

<script>
import api from '~/api';
import Cookies from 'js-cookie';

const ENTER_ONLINE_SERVICE = 5911386759063552;

export default {
    name: 'chat',
    props: {
        isShowChatPopup: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        isShowChatPopup(val) {
            val && this.openAthenaChat();
        }
    },
    methods: {
        openAthenaChat() {
            const gid = Cookies.get('GID');
            const params = {
                // 因为没有did，这里传gid即可
                did: gid || Cookies.get('_gaotu_track_id_') || '',
                appType: 6,
                clientId: 1,
                from: 'gaotu_pc',
                titlebarLess: 1,
                url: window.location.href
            };
            this.$axios
                .$get(api.service.index, {params})
                .then(res => {
                    const {code, data = {}} = res;
                    const {redirect_url = ''} = data;
                    if (redirect_url) {
                        this.$habo.haboClickReport(ENTER_ONLINE_SERVICE, {
                            client_name: 'PC',
                            status: 'success',
                            page: '帮助中心'
                        });
                        redirect_url && (document.querySelector('#iframe').src = redirect_url);
                    }
                    else {
                        this.$habo.haboClickReport(ENTER_ONLINE_SERVICE, {
                            client_name: 'PC',
                            status: 'fail',
                            page: '帮助中心'
                        });
                    }
                });
        },
        close() {
            this.$emit('update:isShowChatPopup', false);
        }
    }
}

</script>
<style lang="less" scoped>
.chat-popup {
    position: fixed;
    bottom: 0;
    right: 20px;
    z-index: 999;
    width: 400px;
    height: 530px;
    background-color: #fff;

    .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        padding: 0 15px;
        background-color: #f8484a;
        color: #fff;
    }
}
</style>
