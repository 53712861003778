export default {
    methods: {
        postIframeData(close, isLogin) {
            window.parent?.postMessage({
                name: 'iframeData',
                data: {
                    close,
                    isLogin
                }
            }, '*');
        }
    }
};
