<template>
    <footer>
        <div class="section-footer">
            <div class="top">
                <div class="col-left">
                    <div class="footer-logo"></div>
                    <div class="company">
                        <template v-for="({ name, type, idOrUrl }, idx) in links">
                            <nuxt-link v-if="type === 1" :key="name" :to="`/html/${idOrUrl}`">{{ name }}</nuxt-link>
                            <a
                                v-else
                                :key="`${idx}-${name}`"
                                :href="idOrUrl"
                                target="_blank"
                                :v-haboClickReport="setEventId(name)"
                                >
                                <span class="suggest">{{ name }}</span>
                            </a>
                        </template>
                    </div>
                </div>
           
                <div class="col-image">
                    <div class="service-header">
                        <img class="service-logo" src="https://i.gsxcdn.com/2141583362_3kkisx3x.png" alt="电话logo">
                        <span class="service-number">{{customServiceNumber}}</span>
                    </div>
                    <div class="service-time">
                        9:00-22:00 （周一至周日)
                    </div>
                </div>
            </div>
            <div class="buttom">
                ICP备案号：{{ ipcNumber }}
            </div>
        </div>
        <chat :isShowChatPopup.sync="isShowChatPopup" />
    </footer>
</template>
<script>
import {mapState} from 'vuex';
import chat from '~/components/common/chat';
import config from '~/environment';

const LinkListUrl = `${config.env.bapiUrl}/cms/footerLink/showlist`;
const ContentListUrl = `${config.env.bapiUrl}/cms/footerContent/showlist`;

const ApolloConfigId = process.env.PATH_TYPE === 'master' ? '6247067946268331126' : '6244176856755610530';
const APOLLO_URL = `https://hydra-cdn.gsxcdn.com/activity-fe/${ApolloConfigId}.json?${Date.now()}`;

export default {
    name: 'SectionFooter',
    components: {
        chat
    },
    computed: {
        ...mapState({
            token: state => state.token,
            commonVariable: state => state.commonVariable
        }),
        // customServiceNumber() {
        //     return this.commonVariable.customServiceNumber;
        // },
    },
    watch: {
        $route(currentRoute) {
            this.path = currentRoute.path;
        }
    },
    data() {
        return {
            path: '',
            isShowChatPopup: false,
            links: [],
            contents: [],
            investUrl: 'https://gsx.investorroom.com/',
            customServiceNumber:'',
            ipcNumber:''
        };
    },
    mounted() {
        this.path = this.$route.path;
        this.getLinkList();
        this.getContentList();
    },
    methods: {
        setEventId(name) {
            let eventId = '';
            switch (name) {
                case '投资者关系':
                    eventId = '4812783089575936';
                    break;
                case '备案内容承诺公示':
                    eventId = '5185332238706688';
                    break;
            }
            return eventId;
        },
        // getLinkList() {
        //     this.$axios.get(LinkListUrl).then(res => {
        //         if (res.data && res.data.data && res.data.data.list) {
        //             this.links = res.data.data.list;
        //             for (const link of this.links) {
        //                 if (link.name === '投资者关系') {
        //                     this.investUrl = link.idOrUrl;
        //                 }
        //             }
        //         }
        //     });
		// },
		getLinkList() {
			this.$axios.get(APOLLO_URL).then(res => {
				this.links = res?.data?.policyLinks || [];
                this.customServiceNumber = res?.data?.customServiceNumber;
                this.ipcNumber = res?.data?.ipcNumber;
			});
		},
        getContentList() {
            this.$axios.get(ContentListUrl).then(res => {
                if (res.data && res.data.data && res.data.data.list) {
                    // const contents = [];
                    // 每两条数据拼接成一行
                    // res.data.list.forEach((content, idx) => {
                    //     const contentIdx = Math.floor(idx / 2);
                    //     if (contents[contentIdx]) {
                    //         contents[contentIdx] = `${contents[contentIdx]}<span class="offset-left">${content}</span>`;
                    //     }
                    //     else {
                    //         contents[contentIdx] = `<span>${content}</span>`;
                    //     }
                    // });
                    // 新版暂时不拼接成一行
                    this.contents = res.data.data.list;
                }
            });
        },
        onChatHandler() {
            this.isShowChatPopup = true;
        },
        gotoSuggest() {
            if (!this.token) {
                this.$store.commit('SET_ISSHOWLOGIN', true);
                return;
            }
            this.$router.push({path: '/suggest'});
        },
    }
};
</script>
<style>
.offset-left {
    margin-left: 6px;
}
</style>
<style lang="less" scoped>
footer {
    background: #fff;
}

.section-footer {
    padding: 52px 0 0 0;
    width: 1182px;
    margin: 0 auto;
	background-color: #fff;

    .top {
        display: flex;
        justify-content: space-between;
    }

    .buttom {
        color: #B7B7BC;
        font-size: 12px;
        font-family: Inter;
        font-weight: 400;
        word-wrap: break-all;
        border-top:  1px solid rgba(179, 179, 187, 0.30);
        padding: 27px 0 28px 0;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

	.col-left {

         .footer-logo {
            width: 325px;
            height: 46px;
            background-image: url(//i.gsxcdn.com/2118849557_dxvpyw8y.png);
            background-size: contain;
            background-repeat: no-repeat;
        }

        .company {
            text-align: left;
            padding: 42px 0 40px 0;

            a {
                font-size: 14px;
                position: relative;
                display: inline-block;
                height: 20px;
                line-height: 20px;
                text-decoration: none;
                color: #0C0B22;
                text-align: center;
                padding: 0 16px;
                font-weight: 500;

                &:after {
                    content: "";
                    width: 1px;
                    height: 10px;
                    position: absolute;
                    right: -1px;
                    top:5px;
                    // top: 3px;
                    background-color: #B3B9C7;
                    // background-color: greenyellow;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:last-child:after{
                    display: none;
                }

                &:hover {
                    color: #f73535;
                }
            }

            .suggest {
                color: #1E212A;
                font-size: 14px;
                line-height: 20px;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    color: #f73535;
                }
            }
        }

	}

    .col-image {
        display: flex;
        flex-direction: column;
        align-content: flex-end;
        justify-content: flex-end;
        padding-bottom: 40px;

            .service-header {
                display: flex;
                height: 28px;
                line-height: 28px;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 3px;

                .service-logo {
                    height: 20px;
                    width: 20px;
                    margin-right: 6px;
                }

                .service-number {
                    color: #333333;
                    font-size: 20px;
                    font-family: PingFang SC;
                    font-weight: 600;
                    word-wrap: break-all;
                }
            }

            

            .service-time {
                display: flex;
                justify-content: space-between;
                align-items: center;

                color: #717274;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;

            }
        
    }

    .company-info {
        font-size: 12px;
        line-height: 20px;
        color: #b3b9c7;
        margin: 0;
    }
   
}
</style>
