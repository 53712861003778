/**
 * 配合海外区号选择模块使用的 mixin
 */
import {FOREIGN_MOBILE_MAX_LENGTH, CHINESE_MOBILE_MAX_LENGTH} from '@base/constants/mobileReg';
import {mapState} from 'vuex';

export default {
    data() {
        return {
            defaultCountryCode: '+86',
            countryCodeSelected: '+86',
            mobile: ''
        };
    },
    computed: {
        ...mapState({
            countryCode: state => state.countryCode
        }),
        mobileMaxLength() {
            return this.countryCodeSelected === '+86' ? CHINESE_MOBILE_MAX_LENGTH : FOREIGN_MOBILE_MAX_LENGTH;
        }
    },
    mounted() {
        // 同步不同页面中该组件的区号
        this.countryCodeSelected = this.countryCode;
    },
    methods: {

        /**
         * 构造用于拼接了区号的国外手机号,
         * 拼接规则：
         * 如果是国外的手机号，则区号前面补两个0，后面接上手机号，即 ‘00’ + 区号 + 手机号， 举个例子： '00 1 188111xxxx'  1 代表美国或者加拿大
         *
         * @return {string} 拼接了区号的国外手机号
         */
        foreignMobileBuilder() {
            return `00${this.countryCodeSelected.substr(1)}${this.mobile}`;
        },

        /**
         * 判断需不需要提交国外手机号
         *
         * @return {string} 手机号
         */
        mobileFormated() {
            return this.countryCodeSelected === this.defaultCountryCode ? this.mobile : this.foreignMobileBuilder();
        },

        // 处理海外区号
        handleCountryOrRegionCode({code}) {
            this.countryCodeSelected = code;
        }
    }
};
