<template>
    <div class="page-container">
        <nuxt />
        <stater />
    </div>
</template>
<script>
import Stater from '~/components/common/Stater';

export default {
    components: {
        Stater
    }
};
</script>
