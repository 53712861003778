<template>
    <div class="page-container">
        <component-header />
        <nuxt />
        <!-- <side-fixed-menu :key="token" /> -->
        <component-footer-cms />
        <!-- <component-footer /> -->
        <stater />
        <modal
            name="global-modal"
            transition="nice-modal-fade"
            :click-to-close="false"
            width="538"
        >
            <div class="common-popup-modal">
                <div class="popup__head">
                    {{globalModalData.title || '郑重声明'}}
                    <i class="icon iconfont icon-guanbi" @click="$modal.hide('global-modal')"></i>
                </div>
                <div
                    v-html='globalModalData.content || "防诈骗声明"'
                    class="popup__body"
                    @click="closeModal"
                >
                </div>
                <div class="btnBox">
                    <button
                        class="modal-btn"
                        @click="modalClickHaddle"
                    >
                        {{globalModalData.btnText || '查看详情'}}
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
/* eslint-disable no-magic-numbers */
import Cookies from 'js-cookie';
import ComponentHeader from '~/components/common/NewHeader';
import ComponentFooter from '~/components/common/NewFooter';
import ComponentFooterCms from '~/components/common/NewFooterCMSS';
import SideFixedMenu from '~/components/common/SideFixedMenu';
import Stater from '~/components/common/Stater';
import {mapState} from 'vuex';
import Logger from '@base/util/logger';
import {ROUTE_WHITE_LIST} from '@base/constants/common';
import Vue from 'vue';

// import axios from 'axios';
// import api from '~/api';

// 防诈骗声明
const GLOBAL_MODAL_CONFIG_URL = 'https://hydra-cdn.gsxcdn.com/activity-fe/5915244761254526464.json';
// 点击跳转超链接地址
const FRAUD_PREVENTION_STATEMENT_URL = 'https://mp.weixin.qq.com/s/OgTDE2fp8vHpfNigUTthkw';

// 防诈骗声明弹窗曝光-高途
const EVENT_ID_GLOBAL_MODAL_SHOW = 9098203922720768;
// 防诈骗声明入口点击-高途
const EVENT_ID_GLOBAL_MODAL_CLICK = 9098213110474752;

export default {
    components: {
        ComponentHeader,
        ComponentFooter,
        ComponentFooterCms,
        SideFixedMenu,
        Stater,
    },
    data() {
        return {
            globalModalData: {},
            pageTDK: {}
        };
    },
    computed: {
        ...mapState({
            token: state => state.token,
            tdk: state => state.tdk,
            utmData: state => state.utmData,
            footerCms: state => state.footerCms
        })
    },


    head() {
        if (this.pageTDK?.title) {
            const {title, keyWord, descInfo} = this.pageTDK;
            return {
                title,
                meta: [
                    {
                        hid: 'keywords',
                        name: 'keywords',
                        content: keyWord
                    },
                    {
                        hid: 'description',
                        name: 'description',
                        content: descInfo
                    }
                ],
            };
        }
        return {};
    },
    mounted() {
        this.setTrackId();
        this.getModalData();
        // 延后异步加载 umeng 插件
        let timer;
        this.$nextTick(() => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                const cnzzStag = document.createElement('script');
                cnzzStag.type = 'text/javascript';
                cnzzStag.async = true;
                cnzzStag.charset = 'utf-8';
                cnzzStag.src = '//s22.cnzz.com/z_stat.php?id=1271279500&web_id=1271279500&async=1';
                const rootS = document.getElementsByTagName('script')[0];
                rootS.parentNode.insertBefore(cnzzStag, rootS);
            }, 600);
        });
    },
    methods: {
        setTrackId() {
            const track_id = Cookies.get('_gaotu_track_id_');
            if (!track_id) {
                // eslint-disable-next-line no-inner-declarations
                function s4() {
                    // eslint-disable-next-line no-magic-numbers
                    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
                }
                // eslint-disable-next-line no-underscore-dangle
                const _guid = function () {
                    return [
                        s4() + s4(),
                        s4(),
                        s4(),
                        s4(),
                        s4() + s4() + s4()
                    ].join('-');
                };
                Cookies.set('_gaotu_track_id_', _guid(), {
                    // eslint-disable-next-line no-magic-numbers
                    expires: 1 * 24 * 365 * 100,
                    domain: '.gaotu.cn'
                });
            }
        },

        // 获取modal中的主体内容
        getModalData() {
            // modal根据cookies状态判断是否展示,非1展示
            const isModalShow = Cookies.get('_gaotu_modal_show_');

            this.$axios.$get(GLOBAL_MODAL_CONFIG_URL).then(res => {
                if (res.isShow) {
                    this.globalModalData = res.data;
                    // 状态不为1，展示，修改状态，埋点
                    if (+isModalShow !== 1) {
                        Cookies.set('_gaotu_modal_show_', 1, {
                            expires: 1
                        });
                        this.$modal.show('global-modal');
                        this.$habo.haboViewPage(EVENT_ID_GLOBAL_MODAL_SHOW);
                    }
                }
            });
        },

        // modal按钮点击事件
        modalClickHaddle() {
            const jumpUrl = this.globalModalData.jumpUrl || FRAUD_PREVENTION_STATEMENT_URL;
            // 跳转防诈骗公众号链接，关闭modal，埋点
            this.$modal.hide('global-modal');
            this.$habo.haboClickReport(EVENT_ID_GLOBAL_MODAL_CLICK);
            window.open(jumpUrl);
        },

        // 点击返回数据中的a标签出发的事件，关闭modal，埋点
        closeModal(e) {
            if (e.target.nodeName === 'A') {
                this.$modal.hide('global-modal');
                this.$habo.haboClickReport(EVENT_ID_GLOBAL_MODAL_CLICK);
            }
        },
        // 获取页面tdk
        getPageTDK() {
            // 只有在路由白名单内，并且请求到数据
            const params = ROUTE_WHITE_LIST[this.$route.name];
            const pageTDK = Vue.prototype.pageTDK?.[params];
            this.pageTDK = pageTDK;
        },
    },
    created() {
        if (!process.browser) {
            this.getPageTDK();
            new Logger().render('服务器发送响应[node.server]', {
                trackId: this.$axios.trackId,
                path: this.$route.fullPath
            });
        }
    }
};

</script>

<style lang="less" scoped>
    .btnBox {
        width: 100%;
        display: flex;
        justify-content: center;
        .modal-btn {
            margin: -6px auto 32px;
            width: 260px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            background-color: #f63232;
            border-radius: 24px;
            font-size: 20px;
            color: #fff;
            cursor: pointer;
            border: none;
        }
    }
    /deep/ .plabel {
        line-height: 24px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1B1E2E;
        margin: 0;
    }
    /deep/ .retract {
        text-indent: 2em;
    }
    /deep/ .linklabel {
        color: #0890FF;
    }
    .popup__body {
        margin-top: 24px !important;
    }
</style>
