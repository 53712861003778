<template>
    <svg
        :class="svgClass"
        aria-hidden="true"
    >
        <use :xlink:href="iconName" />
    </svg>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: {
        // icon名称
        iconClass: {
            type: String,
            required: true
        },
        // 自定义样式
        className: {
            type: String,
            default: ''
        }
    },
    computed: {
        iconName() {
            return `#icon-${this.iconClass}`;
        },
        svgClass() {
            return `svg-icon ${this.className}`;
        }
    }
};
</script>

<style scoped>
.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -.15em;
    fill: none;
    overflow: hidden;
}
</style>
