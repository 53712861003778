<template>
    <section class="section-container">
        <div class="login-box">
            <div class="login-tabs">
                <div class="login-tabs__nav">
                    <div
                        v-show="commonVariable.codeLogin"
                        class="login-tabs__item"
                        :class="{active: loginBy === 'code'}"
                        @click="onSetChangingHandler('code')"
                    >
                        <span class="text">二维码登录</span>
                    </div>
                    <div
                        class="login-tabs__item"
                        data-cy="sms-login-way"
                        :class="{active: loginBy === 'sms'}"
                        @click="onSetChangingHandler('sms')"
                    >
                        <span class="text">验证码登录</span>
                    </div>
                    <div
                        class="login-tabs__item"
                        data-cy="switch-login-way"
                        :class="{active: loginBy === 'mima'}"
                        @click="onSetChangingHandler('mima')"
                    >
                        <span class="text">密码登录</span>
                    </div>
                </div>
                <div class="login-tabs__content">
                    <!--密码登录-->
                    <div
                        v-show="loginBy === 'mima'"
                        class="login-tabs__panel"
                    >
                        <div
                            class="passport-input passport-bottom"
                            :class="{warp: mobile_mima_warp}"
                        >
                            <i class="icon iconfont icon-shoujihao"></i>
                            <foreign-mobile @countryOrRegionCode="handleCountryOrRegionCode" />
                            <input
                                v-model="mobile"
                                name="mima"
                                :maxlength="mobileMaxLength"
                                placeholder="请输入您的手机号"
                                type="tel"
                                class="input-field input-mobile"
                                data-cy="login-pwd-input-mobile"
                                @input="onMobileChangeHandler"
                                @blur="onMiMaBlurMobileHandler"
                            >
                            <div
                                v-if="mobile_mima_warp"
                                class="input-warp"
                            >
                                {{mobile_mima_warp}}
                            </div>
                        </div>
                        <div
                            class="passport-input"
                            :class="{warp: password_warp}"
                        >
                            <i class="icon iconfont icon-mima"></i>
                            <input
                                v-model="password"
                                name="passport-mima"
                                type="text"
                                placeholder="请输入您的密码"
                                class="input-field input-pwd"
                                data-cy="login-pwd-input-pwd"
                                @input="onPasswordChangeHandler"
                                @blur="onBlurPasswordHandler"
                            >
                            <div
                                v-if="password_warp"
                                class="input-warp"
                            >
                                {{password_warp}}
                            </div>
                        </div>
                        <div class="passport-warp">
                            <div
                                v-if="passport_mima_warp"
                                class="passport-warp__text"
                            >
                                {{passport_mima_warp}}
                            </div>
                        </div>
                        <div class="changing-over">
                            <div
                                class="fl"
                                @click="onShowForgetBox"
                            >
                                忘记密码
                            </div>
                        </div>
                        <button
                            type="button"
                            class="passport-btn"
                            data-cy="login-pwd-btn"
                            :disabled="isMimaFetching"
                            :class="{is_loading: isMimaFetching}"
                            @click="onUserLoginHandler"
                        >
                            <span class="passport-btn__text">{{isMimaFetching ? '登录中...' : '登录'}}</span>
                        </button>
                    </div>
                    <!--密码登录-->
                    <!--验证码登录-->
                    <div
                        v-show="loginBy === 'sms'"
                        class="login-tabs__panel"
                    >
                        <div
                            v-haboClickReport="4303089754662912"
                            class="passport-input passport-bottom"
                            :class="{warp: mobile_sms_warp}"
                        >
                            <i class="icon iconfont icon-shoujihao"></i>
                            <foreign-mobile @countryOrRegionCode="handleCountryOrRegionCode" />
                            <input
                                v-model="mobile"
                                name="sms"
                                :maxlength="mobileMaxLength"
                                type="tel"
                                placeholder="请输入您的手机号"
                                class="input-field input-mobile"
                                data-cy="login-sms-input-mobile"
                                @input="onSmsMobileChangeHandler"
                                @blur="onSmsBlurMobileHandler"
                            >
                            <div
                                v-if="mobile_sms_warp"
                                class="input-warp"
                            >
                                {{mobile_sms_warp}}
                            </div>
                        </div>
                        <div class="passport-compose clearfix">
                            <div
                                class="passport-input fl"
                                :class="{warp: inputActive, activeBorder: inputActive}"
                            >
                                <i class="icon iconfont icon-duanxin"></i>
                                <input
                                    v-model="passcode"
                                    type="text"
                                    maxlength="4"
                                    placeholder="请输入您的验证码"
                                    class="input-field"
                                    data-cy="login-sms-input"
                                    @input="onPasscodeChangeHandler"
                                    @blur="onPasscodeBlurHandler"
                                >
                                <div
                                    v-if="passcode_warp"
                                    class="input-warp"
                                >
                                    {{passcode_warp}}
                                </div>
                            </div>
                            <div class="passport-code fr">
                                <button
                                    type="button"
                                    class="passport-code-btn"
                                    data-cy="login-btn-get-sms"
                                    :class="{active:mobile && !count && validateMobile(mobile)}"
                                    :disabled="!mobile || count != 0 || !validateMobile(mobile)"
                                    @click="onGetVerificationCodeHandler"
                                >
                                    <span class="passport-code-btn__text">{{code_btn_text}}</span>
                                </button>
                            </div>
                        </div>
                        <div class="passport-warp">
                            <div
                                v-if="passportSmsWarp"
                                class="passport-warp__text"
                            >
                                {{passportSmsWarp}}
                            </div>
                            <voice-code
                                v-if="mobile && showVoiceCode"
                                :type="1"
                                :mobile="mobile"
                                class="voice-wrapper"
                            />
                        </div>
                        <div class="changing-over">
                            <div
                                class="fl"
                                data-cy="forget-pwd-btn"
                                @click="onShowForgetBox"
                            >
                                忘记密码
                            </div>
                        </div>
                        <button
                            type="button"
                            class="passport-btn"
                            data-cy="login-sms-btn"
                            :disabled="isSmsFetching"
                            :class="{is_loading: isSmsFetching}"
                            @click="onUserSmsLoginHandler"
                        >
                            <span class="passport-btn__text">{{isSmsFetching ? '登录中...' : '注册/登录'}}</span>
                        </button>
                    </div>
                    <!--验证码登录-->
                    <!-- 扫码登录 -->
                    <div
                        v-show="isShowCode"
                        class="login-tabs__panel"
                    >
                        <div class="qr-title">使用<span class="qr-red-color">微信</span>扫描二维码登录</div>
                        <div
                            class="qr-code"
                            :style="{background: `center/contain url(${codeImg})`}"
                        >
                            <div v-show="showShade" class="qr-shade">
                                <div
                                    class="qr-shade-img"
                                    :style="{background: `center/contain url(${shadeImg})`}"
                                ></div>
                                <div class="qr-shade-text">
                                    {{shadeText}}
                                </div>
                            </div>
                        </div>
                        <div class="qr-button" @click="changeCode">刷新二维码</div>
                    </div>
                    <!-- 扫码登录 -->
                </div>
            </div>
            <div
                class="order"
            >
                <p  v-show="loginBy == 'sms'">未注册手机验证后自动登录</p>
                <div
                    class="check-wrapper"
                >
                    <div
                        class="radio-wrapper"
                    >
                        <input
                            id="privacy"
                            type="radio"
                            name="privacy"
                            :checked="checked === true"
                            @click="handleCheck"
                        >
                        <label for="privacy"></label>
                    </div>
                    <p>我已阅读并同意<a :href="protocalUrl.url" target="_blank">{{protocalUrl.name}}</a>和<a :href="policyUrl.url" target="_blank">{{policyUrl.name}}</a></p>
                </div>
            </div>
            <div
                v-show="isShowCode"
                class="order"
            >
                <p class="center">扫码表示已阅读并同意<a :href="protocalUrl.url" target="_blank">{{protocalUrl.name}}</a>和<a :href="policyUrl.url" target="_blank">{{policyUrl.name}}</a></p>
            </div>
        </div>
        <div id="captcha"></div>
    </section>
</template>
<script>
import md5 from 'md5';
import api from '~/api';
import qs from 'qs';
import Cookies from 'js-cookie';
import axios from 'axios';
import VoiceCode from '~/components/common/VoiceCode';
import {captchaId, verifyParamSingle} from '@base/util/common.js';
import {Base64} from 'js-base64';
import {STORAGE__AGREE_POLICY} from '@base/constants/storage-types';
import StorageUtil from '@base/util/storage';
import headersParams from '@base/util/headersParams';
import rsaEncrypt from '@base/util/encrypt';
import getPasscodeOptions from '@base/util/protector';
import ForeignMobile from '@business/components/login/ForeignMobile';
import CountryRegionSelect from '@base/mixins/countryRegionSelect';
import {LOGIN_NOREDIRECT_PATH_NAME} from '@base/constants/common';
import {EVENT_ID_LOGIN_PAGE_VIEW, EVENT_ID_LOGIN_SUCCESS} from '@base/constants/homepage';
import {mapState} from 'vuex';


const getPasscodeReport = '4303091425830912';
const SHADE_ENUM = {
    // 扫码成功
    1: 'success',
    // 授权失败
    2: 'nologin',
    // 登录失败
    3: 'fail',
    // 过期
    4: 'pastdue',
    // loading
    5: 'loading'
}

/* global gio */
export default {
    name: 'Login',
    components: {
        VoiceCode,
        ForeignMobile
    },
    mixins: [CountryRegionSelect],
    props: {
        autoPlay: {
            type: String,
            default: ''
        },
        order: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loginBy: 'code',
            mobile: '',
            password: '',
            mobile_mima_warp: '',
            password_warp: '',
            passport_mima_warp: '',
            isMimaFetching: false,
            passcode: '',
            mobile_sms_warp: '',
            passcode_warp: '',
            passportSmsWarp: '',
            isSmsFetching: false,
            code_btn_text: '获取验证码',
            count: 0,
            timer: null,
            inputActive: false,
            figureTime: 35,
            showVoiceCode: false,
            auto: 0,
            // 二维码状态对象
            shadeStatus: {
                success: {
                    img: 'https://gtoss.gsxcdn.com/1940506804_hum5ukcc.png',
                    text: '扫码成功，请在手机上完成授权以登录'
                },
                nologin: {
                    img: 'https://gtoss.gsxcdn.com/1940506806_927jsgjx.png',
                    text: '扫码授权失败请重新扫描'
                },
                fail: {
                    img: 'https://gtoss.gsxcdn.com/1940506806_927jsgjx.png',
                    text: '登录失败，请刷新重试'
                },
                pastdue: {
                    img: 'https://gtoss.gsxcdn.com/1940506807_lzzu9at6.png',
                    text: '二维码已过期请刷新重试'
                },
                loading: {
                    img: 'https://gtoss.gsxcdn.com/1950724639_ngh0zlgy.gif',
                    text: '加载中'
                }
            },
            // 展示状态蒙层
            showShade: true,
            // 二维码状态
            shadeCode: 'loading',
            // 用户雪花id
            snowId: '',
            // 过期时间
            pastDueTime: 120000,
            // 二维码定时器
            codeTimer: null,
            // 二维码图片
            codeImg: '',
            // 获取用户扫码状态定时器
            userStatusInterval: null,
            // 刷新定时器
            refershTimer: null,
            // 首次登陆
            firstLogin: false,
            // 勾选隐私协议与否
            checked: false,
        };
    },
    computed: {
        ...mapState({
            commonVariable: state => state.commonVariable,
            protocalUrl: state => state.protocalUrl,
            policyUrl: state => state.policyUrl
        }),
        isShowCode() {
            return this.loginBy === 'code' && this.commonVariable.codeLogin;
        },
        shadeImg() {
            return this.shadeStatus[this.shadeCode]?.img || this.shadeStatus.fail.img
        },
        shadeText() {
            return this.shadeStatus[this.shadeCode]?.text || this.shadeStatus.fail.text
        },
    },
    mounted() {
        try {
            if (!this.commonVariable.codeLogin) {
                this.loginBy = 'sms'
            }
            this.haboPageView()
            if (this.commonVariable.codeLogin) {
                this.getWeixinCode();
            }
            // 添加提示隐私政策弹窗
            if (!StorageUtil.get(STORAGE__AGREE_POLICY)) {
                this.$modal.show('privacy-policy-modal');
            }
            // 移除旧的用户
            const cookie_accout = Cookies.get('__user_account__');
            if (cookie_accout) {
                Cookies.remove('__user_account__');
            }
            // 新的
            const new_mobile = Cookies.get('ACCOUNT') || '';
            if (new_mobile && this.validateMobile(Base64.decode(new_mobile))) {
                this.mobile = Base64.decode(new_mobile);
            }
        }
        catch (error) {
        }
    },
    beforeDestroy() {
        clearTimeout(this.userStatusInterval);
        clearTimeout(this.codeTimer);
    },
    methods: {
        toProtocal() {
            this.$emit('hideLoginBox');
            this.$router.push({path: '/protocal'});
        },
        onShowForgetBox() {
            this.$emit('hideLoginBox', 1);
            this.$store.commit('SET_ISSHOWFORGET', true);
        },
        onSetChangingHandler(payload) {
            if (this.loginBy !== payload) {
                this.loginBy = payload;
            }
        },
        onMiMaBlurMobileHandler() {
            if (!this.mobile.trim()) {
                this.mobile_mima_warp = '请输入手机号';
                return;
            }
            if (!this.validateMobile(this.mobile)) {
                this.mobile_mima_warp = '请输入正确手机号';
            }
        },
        onMobileChangeHandler() {
            if (this.mobile.trim()) {
                this.mobile_mima_warp = '';
            }
        },
        onPasswordChangeHandler() {
            this.password = this.password.replace(/[\u0391-\uFFE5]/gi, '');
            if (this.password.trim()) {
                this.password_warp = '';
            }
        },
        onBlurPasswordHandler() {
            if (!this.password.trim()) {
                this.passport_mima_warp = false;
                this.password_warp = '请输入密码';
            }
        },
        onSaveUserAccountHandler(mobile) {
            Cookies.set('ACCOUNT', Base64.encode(mobile), {
                expires: 30,
                path: '/'
            });
        },
        // 登录
        onUserLoginHandler(payload) {
            this.passport_mima_warp = '';

            if (!this.mobile.trim() || !this.password.trim() || !this.validateMobile(this.mobile)) {
                if (!this.mobile.trim()) {
                    this.mobile_mima_warp = '请输入手机号';
                }
                if (this.mobile.trim() && !this.validateMobile(this.mobile)) {
                    this.mobile_mima_warp = '请输入正确手机号';
                }
                if (!this.password.trim()) {
                    this.password_warp = '请输入密码';
                }
                return;
            }
            // 校验登录锁定
            const itemName = `loginLockTime-${this.mobileFormated()}`;
            const lockTime = localStorage.getItem([itemName]);
            if(lockTime) {
                const curTime = new Date().getTime();
                if(curTime - lockTime > 5*60*1000) {
                    localStorage.removeItem([itemName]);
                }else {
                    const showTime = 5 - Math.floor((curTime - lockTime)/60/1000);
                    this.$toasted.show(`您已经连续登录失败5次，账号已被锁定${showTime}分钟`, {
                        position: 'top-center',
                        duration: 1500,
                    });
                    return;
                }
            }
            // 校验隐私协议
            if(!this.checked) {
                this.$toasted.show('请勾选同意下方协议', {
                    position: 'top-center',
                    duration: 1500,
                });
                return;
            }
            this.isMimaFetching = true;
            const params = {
                mobile: this.mobileFormated(),
                password: md5(this.password),
                passwordOrig: rsaEncrypt(this.password),
                isEncrypted: true,
                ...payload
            };

            const {source} = this.$route.query;
            if (source) {
                params.source = verifyParamSingle(source);
            }
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                },
                ...headersParams
            };
            this.$axios.$post(api.user.login, params, config).then(res => {
                const {code, msg, data = {}, firstLogin = false} = res;
                // test 密码有效期不超过180天
                if(code === 180) {
                    this.$toasted.show('您的密码即将过期，建议您更换新密码', {
                        position: 'top-center',
                        duration: 1000,
                    });
                }
                if (code === 0) {
                    this.handleLoginSuccess();
                    this.$store.dispatch('GET_ENTRANCE');
                    this.$store.commit('TOKEN', data.sessionId);
                    this.$store.commit('USER_INFO', data);
                    this.$store.dispatch('AFTER_LOGIN_SUCCESS');
                    this.onSaveUserAccountHandler(this.mobile);
                    this.$emit('hideLoginBox');
                    this.firstLogin = firstLogin;
                    this.haboSuccess();

                    // growingio 采集登录用户信息
                    window.gio && gio('setUserId', data.userId);

                    /* 后置登录自动播放和自动预约的中间参数监听 */

                    if (this.autoPlay === 1) {
                        this.$router.replace('/refresh?autoPlaying=1');
                    }
                    else if (this.order === 1) {
                        this.$router.replace('/refresh?ordering=1');
                    }
                    else if (!LOGIN_NOREDIRECT_PATH_NAME.includes(this.$route.name)) {
                        this.$router.replace('/refresh');
                    }
                }
                else {
                    this.isMimaFetching = false;
                    if (code === 36) {
                        this.$emit('hideLoginBox');
                        this.$emit('showRegPop');
                        // this.$modal.show('12-login-popup')
                    }
                    else if (code === 130) {
                        this.onGetSlidePassCode('mima');
                    }
                    else {
                        this.passport_mima_warp = msg;
                        // 记录登录失败
                        this.handleLoginFail();
                    }
                }
            });
        },
        handleLoginSuccess() {
            // 清除登录失败记录
            localStorage.setItem(`loginFailTime-${this.mobileFormated()}`, JSON.stringify([]));
            localStorage.setItem(`loginLockTime-${this.mobileFormated()}`, '');
        },
        handleLoginFail() {
            const itemName = `loginFailTime-${this.mobileFormated()}`;
            let failRecord = localStorage.getItem([itemName]) ? JSON.parse(localStorage.getItem([itemName])) : [];
            const currentTime = new Date().getTime();
            if (failRecord.length < 4) {
                failRecord.push(currentTime);
            }
            else if (failRecord.length === 4) {
                // 一分钟内连续输入5次，则锁定账号5分钟
                const needForbidden = (currentTime - failRecord[0]) < (60 * 1000);
                if(!needForbidden) {
                    // 第五次失败距离第一次失败大于1分钟，则删掉第一个记录
                    failRecord.shift();
                    failRecord.push(currentTime);
                }
                else {
                    failRecord = [];
                    // 1分钟内连续失败五次，则锁定账号
                    const itemName = `loginLockTime-${this.mobileFormated()}`;
                    localStorage.setItem([itemName], new Date().getTime());
                    this.$toasted.show('您已经连续登录失败5次，账号已被锁定5分钟', {
                        position: 'top-center',
                        duration: 2000,
                    });
                }
            }
            localStorage.setItem(`loginFailTime-${this.mobileFormated()}`, JSON.stringify(failRecord));
        },
        onGetSlidePassCode(type) {
            // type: 0注册 1登录 2忘记密码 3登陆注册 12绑定手机
            const params = {
                type: 3,
                mobile: this.mobileFormated(),
                captchaMode: 'NETEASE',
                captchaGuid: captchaId
            };
            let captchaIns;
            const that = this;
            initNECaptcha({
                element: '#captcha',
                captchaId,
                mode: 'popup',
                width: '500px',
                onVerify(err, data) {
                    // 用户验证码验证成功后，进行实际的提交行为
                    if (data) {
                        params.captcha = data.validate;
                        if (type === 'passcode') {
                            that.getVerificationCode(params);
                        }
                        else {
                            that.onUserLoginHandler(params);
                        }
                    }
                }
            }, instance => {
                // 初始化成功后得到验证实例instance，可以调用实例的方法
                captchaIns = instance;
                captchaIns && captchaIns.popUp();
            }, err => {
                // 初始化失败后触发该函数，err对象描述当前错误信息
            });
        },
        // Sms登录
        onGetVerificationCodeHandler() {
            this.$habo.haboClickReport(getPasscodeReport);
            this.passcode_warp = '';
            const params = {
                mobile: this.mobileFormated()
            };
            this.getVerificationCode(params);
        },
        getVerificationCode(payload) {
            const params = {type: 3, ...payload};
            this.passportSmsWarp = '';
            this.$axios.$post(api.user.sendPasscode, qs.stringify({
                ...params,
                ...getPasscodeOptions()
            })).then(res => {
                const {code, msg} = res;
                switch (code) {
                    case 0:
                        this.onCountdownHandler();
                        this.passportSmsWarp = '验证码已发送到您的手机';
                        break;
                    case 12:
                        this.$emit('hideLoginBox');
                        this.$emit('showRegPop');
                        break;
                    case 130:
                        this.onGetSlidePassCode('passcode');
                        break;
                    default:
                        this.passportSmsWarp = msg;
                        break;
                }
            });
        },
        onCountdownHandler() {
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.code_btn_text = `${this.count}秒后重发`;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                        if (this.count === this.figureTime) {
                            this.showVoiceCode = true;
                        }
                        this.code_btn_text = `${this.count}秒后重发`;
                    }
                    else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.code_btn_text = '获取验证码';
                    }
                }, 1000);
            }
        },
        onSmsBlurMobileHandler() {
            this.passportSmsWarp = '';
            if (!this.mobile.trim()) {
                this.mobile_sms_warp = '请输入手机号';
                return;
            }
            if (!this.validateMobile(this.mobile)) {
                this.mobile_sms_warp = '请输入正确手机号';
            }
        },
        // 验证码
        onSmsMobileChangeHandler() {
            if (this.mobile.trim()) {
                this.mobile_sms_warp = '';
            }
        },
        onPasscodeChangeHandler() {
            if (this.passcode.trim()) {
                this.passcode_warp = '';
            }
            this.passcode = this.passcode.replace(/\D+/, '');
        },
        onPasscodeBlurHandler() {
            this.passportSmsWarp = '';
            if (!this.passcode.trim()) {
                this.passcode_warp = '请输入验证码';
                return;
            }
            if (this.passcode.trim().length < 4 || this.passcode.trim().length === 5) {
                this.inputActive = true;
                this.passcode_warp = '请输入正确验证码';
            }
            else {
                this.inputActive = false;
                this.passcode_warp = '';
            }
        },
        onUserSmsLoginHandler() {
            this.passportSmsWarp = '';

            if (!this.mobile.trim()) {
                this.mobile_sms_warp = '请输入手机号';
                return;
            }

            if (this.mobile.trim() && !this.validateMobile(this.mobile)) {
                this.mobile_sms_warp = '请输入正确手机号';
                return;
            }
            // 校验登录锁定
            const itemName = `loginLockTime-${this.mobileFormated()}`;
            const lockTime = localStorage.getItem([itemName]);
            if(lockTime) {
                const curTime = new Date().getTime();
                if(curTime - lockTime > 5*60*1000) {
                    localStorage.removeItem([itemName]);
                }else {
                    const showTime = 5 - Math.floor((curTime - lockTime)/60/1000);
                    this.$toasted.show(`您已经连续登录失败5次，账号已被锁定${showTime}分钟`, {
                        position: 'top-center',
                        duration: 1500,
                    });
                    return;
                }
            }

            if (this.passcode.trim().length < 4 || this.passcode.trim().length === 5) {
                this.passcode_warp = '请输入正确验证码';
                return;
            }

            if (!this.passcode.trim()) {
                this.passcode_warp = '请输入验证码';
                return;
            }
            // 校验隐私协议
            if(!this.checked) {
                this.$toasted.show('请勾选同意下方协议', {
                    position: 'top-center',
                    duration: 1500,
                });
                return;
            }

            this.isSmsFetching = true;
            const params = {
                mobile: this.mobileFormated(),
                passcode: this.passcode
            };
            const {source} = this.$route.query;
            if (source) {
                params.source = verifyParamSingle(source);
            }
            this.$axios.$post(api.user.loginSms, qs.stringify(params), headersParams).then(res => {
                const {code, msg, data = {}} = res;
                if (code === 0) {
                    this.handleLoginSuccess();
                    this.$store.dispatch('GET_ENTRANCE');
                    this.$store.commit('TOKEN', data.sessionId);
                    this.$store.commit('USER_INFO', data);
                    this.$store.dispatch('AFTER_LOGIN_SUCCESS');
                    this.onSaveUserAccountHandler(this.mobile);
                    this.$emit('hideLoginBox');
                    this.haboSuccess();

                    // growingio 采集登录用户信息
                    window.gio && gio('setUserId', data.userId);

                    /* 后置登录自动播放和自动预约的中间参数监听 */
                    if (this.autoPlay === 1) {
                        this.$router.replace('/refresh?autoPlaying=1');
                    }
                    else if (this.order === 1) {
                        this.$router.replace('/refresh?ordering=1');
                    }
                    else if (!LOGIN_NOREDIRECT_PATH_NAME.includes(this.$route.name)) {
                        this.$router.replace('/refresh');
                    }
                }
                else if (res.status === 12) {
                    this.$emit('hideLoginBox');
                    this.$emit('showRegPop');
                    // this.$modal.show('12-login-popup')
                }
                else {
                    this.passportSmsWarp = msg;
                    this.isSmsFetching = false;
                    // 记录登录失败
                    this.handleLoginFail();
                }
            });
        },
        // 获取二维码
        getWeixinCode() {
            const options = {
                params: {
                    p_client: 15,
                    os: 'h5-pc'
                },
            };
            this.$axios.$get(api.user.getWeixinCode, options).then(res => {
                if (res.code === 0) {
                    const {snowflakeId, effectiveSwitch, pollingTime, pictureBase64} = res.data
                    this.showShade = false;
                    this.snowId = snowflakeId;
                    this.pastDueTime = pollingTime;
                    this.codeImg = pictureBase64;
                    this.codePastDue();
                }
            });
        },
        // 刷新二维码
        changeCode() {
            if (this.refershTimer) {
                this.$toasted.show('操作频繁，请稍后再试', {
                    theme: 'toasted-primary',
                    position: 'top-center',
                    duration: 2000
                });
                return;
            }
            else {
                clearTimeout(this.userStatusInterval);
                this.showShade = true;
                this.shadeCode = 'loading';
                this.getWeixinCode();
                this.refershTimer = setTimeout(() => {
                    clearTimeout(this.refershTimer)
                    this.refershTimer = null;
                }, 10000)
            }
            
        },
        // 二维码过期逻辑
        codePastDue() {
            if (this.codeTimer) {
                clearTimeout(this.codeTimer);
            }

            this.pollingGetUserStatus();

            this.codeTimer = setTimeout(() => {
                this.shadeCode = 'pastdue';
                this.showShade = true;
                clearTimeout(this.userStatusInterval);
                clearTimeout(this.codeTimer);
            }, this.pastDueTime)
        },
        // 轮询获取用户扫码登录状态
        pollingGetUserStatus() {
            if (this.userStatusInterval) {
                clearTimeout(this.userStatusInterval);
            }

            this.userStatusInterval = setTimeout(() => {
                this.$axios.$get(api.user.pollByKey, {
                    params: {
                        scanKey: this.snowId
                    }
                }).then(res => {
                    if (res.code === 0) {
                        const {status, sid = '', mobile = '', userId = '', firstLogin = false} = res.data;
                        // status -1表示未知key；0 表示登陆成功；1表示扫码成功；2表示授权失败（用户点击取消）；3表示小程序登陆接口处理失败
                        if (status === 0) {
                            this.firstLogin = firstLogin;
                            this.$store.commit('TOKEN', sid);
                            this.onSaveUserAccountHandler(mobile);
                            // growingio 采集登录用户信息
                            window.gio && gio('setUserId', userId);
                            this.getUserData(sid)
                        }
                        else if (status !== -1){
                            this.pollingGetUserStatus();
                            this.shadeCode = SHADE_ENUM[status] || 'fail';
                            this.showShade = true;
                        }
                        else {
                            this.pollingGetUserStatus();
                        }
                    }
                    else {
                        this.pollingGetUserStatus();
                    }
                }).catch(e => {
                    console.error(e);
                    this.pollingGetUserStatus();
                })
            }, 1000)
        },
        // 页面唤起，habo 上报
        haboPageView() {
            const {name} = this.$route;
            const haboParams = {
                'index': {
                    page: '首页',
                    page_id: '9268082668431360'
                },
                'category*': {
                    page: '选课中心页',
                    page_id: '5660940051048448'
                }
            };
            const params = haboParams[name] || {
                page: '',
                page_id: ''
            }
            this.$habo.haboViewPage(EVENT_ID_LOGIN_PAGE_VIEW, params);
        },
        // 登录成功，habo上报
        haboSuccess() {
            const {firstLogin, loginBy} = this;
            const haboParams = {
                'code': 'scan_login',
                'sms': 'message_login',
                'mima': 'password_login'
            };
            const params = {
                login_method: haboParams[loginBy],
                first_login: firstLogin
            };
            this.$habo.haboViewPage(EVENT_ID_LOGIN_SUCCESS, params);
        },
        // 获取用户登录后详细信息
        getUserData(sid) {
            this.$axios.$get(api.user.profile, {
                params: {
                    sid
                }
            }).then(resp => {
                const {code, msg, data = {}} = resp;
                if (code === 0) {
                    this.$store.dispatch('GET_ENTRANCE');
                    this.$store.commit('USER_INFO', data);
                    this.$store.dispatch('AFTER_LOGIN_SUCCESS');
                    this.$emit('hideLoginBox');
                    this.haboSuccess();

                    /* 后置登录自动播放和自动预约的中间参数监听 */

                    if (this.autoPlay === 1) {
                        this.$router.replace('/refresh?autoPlaying=1');
                    }
                    else if (this.order === 1) {
                        this.$router.replace('/refresh?ordering=1');
                    }
                    else if (!LOGIN_NOREDIRECT_PATH_NAME.includes(this.$route.name)) {
                        this.$router.replace('/refresh');
                    }

                }
                else {
                    this.isMimaFetching = false;
                    if (code === 36) {
                        this.$emit('hideLoginBox');
                        this.$emit('showRegPop');
                        // this.$modal.show('12-login-popup')
                    }
                    else if (code === 130) {
                        this.onGetSlidePassCode('mima');
                    }
                    else {
                        this.passport_mima_warp = msg;
                    }
                }
            })
        },
        // 处理勾选隐私协议
        handleCheck() {
            this.checked = !this.checked;
        }
    }
};
</script>
<style lang="less" scoped>
@font-face{
    font-family: text-security-disc;
    src: url("https://raw.githubusercontent.com/noppa/text-security/master/dist/text-security-disc.woff") format("woff");
}
.check-wrapper {
    display: flex;
    align-items: center;
    margin-top: -16px;

    .radio-wrapper {
        input {
            margin-right: 8px;
        }

        input[type="radio"] + label::before {
            content: "\a0";
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            width: 13px;
            height: 13px;
            margin-right: .4em;
            border-radius: 50%;
            border: 1px solid #b4b4b4;
            line-height: 1;
            box-sizing: border-box;
        }

        input[type="radio"]:checked + label::before {
            padding: 2px;
            box-sizing: border-box;
            border-color: #f63232;
            background-color: #f63232;
            background-clip: content-box;
        }

        input[type="radio"] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
        }
    }
}

input.input-pwd {
    font-family: text-security-disc;
    -webkit-text-security: disc;
}

.section-container {
    padding-left: 43px;
    height: 480px;
    padding-bottom: 60px;

    .center {
        text-align: center;
        margin-left: -43px;
        width: 100%;
    }
}
.changing-over {
    position: relative;
    padding: 0 0 14px;
    height: 16px;
    .fl {
        font-size: 14px;
        color: #f63232;
        transition: all .3s;
        cursor: pointer;
        &:hover {
            font-weight: 700;
        }
    }
}
.order {
    margin: 36px 0 -40px;

    p {
        font-size: 12px;
        color: #32404b;
        line-height: 16px;
        span {
            font-size: 12px;
            color: #f63232;
            line-height: 16px;
            cursor: pointer;
        }
        a {
            color: #f63232;
        }
    }
}
.voice-wrapper {
    right: 0;
    top: 11px;
}
.login-box {
    position: relative;
    background: #fff;
    width: 536px;
    border-radius: 8px;
    margin: 0 auto;
}
.login-tabs {
    position: relative;
    padding: 23px 0 0;
    &__nav {
        display: table;
        text-align: center;
        width: 444px;
        margin-bottom: 30px;
    }
    &__item {
        display: table-cell;
        // width: 50%;
        font-size: 0;
        cursor: pointer;
        .text {
            display: inline-block;
            font-size: 20px;
            color: #818a92;
            padding: 0 0 20px;
            border-bottom: 2px solid transparent;
            transition: all .3s;
        }
        &.active {
            .text {
                color: #32404b;
                border-bottom-color: #f63232;
            }
        }
    }

    &__content {
        width: 444px;

        .passport-btn__text {
            font-size: 20px;
        }
        .input-mobile {
            width: 240px;
            padding-left: 0;
        }

        .qr-title {
            text-align: center;
            font-size: 20px;
            line-height: 30px;
            font-weight: 500;
            color: #1b1e2e;
            margin-top: 43px;
        }

        .qr-red-color {
            color: #ff443d;
        }

        .qr-code {
            margin: 20px auto;
            width: 190px;
            height: 190px;
            position: relative;
            border-radius: 8px;
            border: 1px solid #ececec;
        }

        .qr-shade {
            width: 190px;
            height: 190px;
            border-radius: 8px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, .95);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &-img {
                width: 44px;
                height: 44px;
            }

            &-text {
                margin-top: 2px;
                width: 146px;
                text-align: center;
                line-height: 24px;
                font-size: 16px;
                color: #414455;
                font-weight: 500;
            }
        }

        .qr-button {
            margin: 0 auto;
            width: 160px;
            height: 32px;
            border-radius: 18px;
            background-color: #eff2f8;
            text-align: center;
            line-height: 32px;
            color: #414455;
            cursor: pointer;
            font-size: 14px;
            position: relative;
            padding-left: 20px;

            &::before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                background: center/contain url(https://gtoss.gsxcdn.com/1940506033_0tg7o5vd.png);
                position: absolute;
                top: 8px;
                left: 42px;
            }
        }
    }
}

</style>

<style lang="less">

</style>
