<template>
    <div v-if="isChineseMobile" class="voice-code">
        <div class="voice-code__right" @click="onShowDialogHandler">收不到验证码?</div>
        <modal class="voice-prompt" name="voice-code" :width="340" height="auto" transition="nice-modal-fade">
            <div class="voice-prompt__title">
                语音验证码
                <i class="icon iconfont icon-guanbi" @click="onCloseModalHandler"></i>
            </div>
            <div class="voice-prompt__content">
                我们将以电话形式告知您验证码，您可能会接到125开头的来电，请放心接听
            </div>
            <div class="voice-prompt__operation">
                <div
                    class="cancel-btn"
                    @click="onCloseModalHandler"
                >
                    取消
                </div>
                <div class="answer-btn" @click="onAnswerHandler">现在接听</div>
            </div>
        </modal>
    </div>
</template>

<script>
import qs from 'qs';
import api from '~/api';
import {captchaId} from '@base/util/common.js';
import getPasscodeOptions from '@base/util/protector';

export default {
    props: {
        // eslint-disable-next-line vue/require-default-prop
        mobile: String,
        // eslint-disable-next-line vue/require-default-prop
        type: Number
    },
    data() {
        return {
            code_type: 1,
            captcha: null,
            captchaPopupShow: false,
            code: ''
        };
    },
    computed: {
        isChineseMobile() {
            const {countryCode} = this.$store.state;
            return countryCode === '+86';
        }
    },
    methods: {
        // 获取滑动验证码
        onSlidePassCode() {
            const params = {
                type: this.type,
                mobile: this.mobile,
                codeType: this.code_type,
                captchaMode: 'NETEASE',
                captchaGuid: captchaId
            };
            let captchaIns;
            const that = this;
            // eslint-disable-next-line no-undef
            initNECaptcha({
                element: '#captcha',
                captchaId,
                mode: 'popup',
                width: '500px',
                // eslint-disable-next-line handle-callback-err
                onVerify(err, data) {
                    // 用户验证码验证成功后，进行实际的提交行为
                    if (data) {
                        params.captcha = data.validate;
                        that.getVerificationCode(params);
                    }
                }
            }, instance => {
                // 初始化成功后得到验证实例instance，可以调用实例的方法
                captchaIns = instance;
                captchaIns && captchaIns.popUp();
            }, err => {
                // 初始化失败后触发该函数，err对象描述当前错误信息
            });
        },
        // onShowDialogHandler(){
        //     Dialog.confirm({
        //         title: '语音验证码',
        //         message: '我们将以电话形式告知您验证码，您可能会接到125开头的来电，请放心接听',
        //         confirmButtonText: '现在接听',
        //         className: 'common-dialog'
        //     }).then(() => {
        //         this.onGetVoiceCodeHandler()
        //     }).catch(() => {});
        // },
        onAnswerHandler() {
            this.onGetVoiceCodeHandler();
        },
        onCloseModalHandler() {
            this.$modal.hide('voice-code');
        },
        onShowDialogHandler() {
            this.$modal.show('voice-code');
        },
        onGetVoiceCodeHandler() {
            const params = {
                type: this.type,
                mobile: this.mobile,
                code_type: this.code_type
            };
            this.getVerificationCode(params);
        },
        getVerificationCode(payload) {
            this.$axios.$post(api.user.sendPasscode, qs.stringify({
                ...payload,
                ...getPasscodeOptions()
            })).then(res => {
                this.$modal.hide('voice-code');
                const {code, msg} = res;
                switch (code) {
                    case 0:
                        this.$modal.show('stater-common-popup', {error_info: '电话已拨打，请留意接听'});
                        break;
                    case 11:
                        this.$modal.show('stater-common-popup', {error_info: msg});
                        break;
                    case 12:
                        this.$modal.show('stater-common-popup', {error_info: msg});
                        break;
                    case 130:
                        this.onSlidePassCode();
                        break;
                    default:
                        this.$modal.show('stater-common-popup', {error_info: msg});
                        break;
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.voice-code {
    position: absolute;
    width: 110px;
    font-size: 14px;
    color: #f63232;
    height: 28px;
    line-height: 28px;

    &__right {
        cursor: pointer;
    }

    .voice-prompt {
        &__title {
            position: relative;
            height: 70px;
            line-height: 70px;
            color: #32404b;
            font-size: 20px;
            text-align: center;

            .iconfont {
                display: inline-block;
                position: absolute;
                line-height: 16px;
                top: 15px;
                right: 15px;
                cursor: pointer;
            }
        }

        &__content {
            margin: 0 30px;
            border-top: 1px dotted #f5f5f5;
            padding-top: 20px;
            font-size: 20px;
            color: #32404b;
            text-align: center;
        }

        &__operation {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0 30px;
            padding: 0 30px;

            .cancel-btn,
            .answer-btn {
                display: inline-block;
                color: #999;
                border: 1px solid #999;
                border-radius: 4px;
                width: 128px;
                height: 44px;
                line-height: 44px;
                font-size: 20px;
                text-align: center;
                cursor: pointer;
            }

            .answer-btn {
                display: inline-block;
                color: #f63232;
                border: 1px solid #f63232;
            }
        }
    }
}
</style>
