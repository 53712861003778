<template>
    <footer>
        <div class="section-footer" :class="{ourdesc: path === '/'}">
            <div class="wrapper">
                <div class="contact">
                    <!-- pc: 首页-底部 -->
                    <dl class="col-md col-md1">
                        <div
                            class="footer-logo"
                            :style="{backgroundImage: `url(https://i.gsxcdn.com/1721587575_xpi9dxl1.png)`}"
                        >
                        </div>
                    </dl>
                    <dl class="col-md col-md2">
                        <dt>关于我们</dt>
                        <dd>
                            <nuxt-link to="/qualification">教师资质</nuxt-link>
                        </dd>
                        <dd>
                            <a
                                data-habo-params="event_id=4812797339461632"
                                href="https://gsx.investorroom.com/"
                                target="_blank"
                            >投资者关系</a>
                        </dd>
                    </dl>
                    <dl class="col-md col-md3">
                        <dt>用户服务</dt>
                        <dd>
                            <nuxt-link to="/help">帮助中心</nuxt-link>
                        </dd>
                        <dd>
                            <span @click="gotoSuggest" class="suggest">意见反馈</span>
                        </dd>
                        <dd>
                            <a href="javascript:;" @click="onChatHandler">在线客服</a>
                        </dd>
                    </dl>
                    <dl class="col-md col-md4">
                        <dd>
                            <div class="qrcodes clearfix">
                                <div class="qrcode gzh">
                                    <div class="qrcode-img">
                                        <img v-lazy="require('~/assets/img/wechat-code-img.png')" alt="微信公众号">
                                    </div>
                                    <div class="qrcode-text">关注微信服务号</div>
                                </div>
                                <div class="qrcode">
                                    <div class="qrcode-img">
                                        <img v-lazy="require('~/assets/img/app-down-code-img.png')" alt="下载APP">
                                    </div>
                                    <div class="qrcode-text">下载APP</div>
                                </div>
                            </div>
                        </dd>
                    </dl>
                </div>
                <!-- 友情链接 -->
                <div v-if="blogrollShow" class="blogroll">
                    <h4 class="blogroll-title">友情链接</h4>
                    <template
                        v-for="({name, idOrUrl}, idx) in blogroll"
                    >
                        <a
                            :key="`${idx}-${name}`"
                            :href="idOrUrl"
                            target="_blank"
                        >
                            <span class="blogroll-suggest">{{name}}</span>
                        </a>
                    </template>
                </div>
                <!-- 底部 -->
                <div class="base-footer">
                    <div class="company">
                        <nuxt-link to="/protocal">用户协议</nuxt-link>
                        <nuxt-link to="/policy">隐私政策</nuxt-link>
                        <a
                            v-haboClickReport="4812783089575936"
                            href="https://gsx.investorroom.com"
                            target="_blank"
                        >
                            投资者关系
                        </a>
                        <a
                            href="/license.html"
                            target="_blank"
                        >
                            <span class="suggest">营业执照</span>
                        </a>
                        <a
                            href="/commitment.html"
                            target="_blank"
                            data-habo-params="event_id=5185332238706688"
                        >
                            备案内容承诺公示
                        </a>
                        <a
                            href="http://jw.beijing.gov.cn/xxgk/mbjyzl/202009/t20200918_2065665.html"
                            target="_blank"
                        >
                            线上培训备案名单
                        </a>
                    </div>
                    <p class="company-info">
                        <span>Copyright © 2014 - 2021 高途集团版权所有 ICP经营许可证:京B2-20190377</span>
                        <span>广播电视节目制作经营许可证:(京)字第15953号</span>
                    </p>
                    <p class="company-info">
                        <span>北京高途云集教育科技有限公司</span>
                        <span>电话：4000910188</span>
                        <span>地址：北京市海淀区西北旺东路10号院东区7号楼1层W101</span>
                    </p>
                </div>
            </div>
        </div>
        <chat :isShowChatPopup.sync="isShowChatPopup" />
    </footer>
</template>
<script>
import {mapState} from 'vuex';
import chat from '~/components/common/chat';

export default {
    name: 'SectionFooter',
    serverCacheKey: () => 'NewFooter',
    components: {
        chat
    },
    computed: {
        ...mapState({
            token: state => state.token,
            blogroll: state => state.blogroll
        }),
        blogrollShow() {
            const {path = ''} = this.$route;
            return this.blogroll.length > 0 && path === '/';
        }
    },
    watch: {
        $route(currentRoute) {
            this.path = currentRoute.path;
        }
    },
    data() {
        return {
            path: '',
            isShowChatPopup: false
        };
    },
    mounted() {
        this.path = this.$route.path;
        this.$store.dispatch('GET_BLOGROLL');
    },
    methods: {
        onChatHandler() {
            this.isShowChatPopup = true;
        },
        gotoSuggest() {
            if (!this.token) {
                this.$store.commit('SET_ISSHOWLOGIN', true);
                return;
            }
            this.$router.push({path: '/suggest'});
        },
    }
};
</script>
<style lang="less" scoped>
@keyframes footeritemup {
    from {
        top: 150px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.col-md {
    position: relative;
    display: table-cell;
    vertical-align: top;
    opacity: 1;

    dt {
        font-size: 16px;
        color: #0c0b22;
        font-weight: 600;
        line-height: 22px;
    }

    dd {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        cursor: pointer;

        a{
            color: #717274;

            &:hover {
                color: #ff443d;
            }
        }
    }

    &.animate-active {
        animation: .9s footeritemup linear !important;
        animation-fill-mode: forwards;
        -webkit-animation-fill-mode: forwards;
    }
}

.col-md1 {
    width: 514px;
    padding-top: 2px;

    .footer-logo {
        width: 350px;
        height: 36px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &.animate-active {
        animation-delay: 1s !important;
        -webkit-animation-delay: 1s !important;
        animation-fill-mode: forwards !important;
        -webkit-animation-fill-mode: forwards !important;
    }
}

.col-md2 {
    width: 200px;

    &.animate-active {
        animation-delay: 1.5s !important;
        -webkit-animation-delay: 1.5s !important;
        animation-fill-mode: forwards !important;
        -webkit-animation-fill-mode: forwards !important;
    }
}

.col-md3 {
    opacity: 1;
    width: 200px;

    .services {
        position: relative;
        width: 300px;
        &__label {
            float: left;
            width: 70px;
        }

        &__content {
            float: left;

            .telephone {
                font-size: 14px;
                color: #696e80;
                line-height: 20px;
                margin-bottom: 6px;
            }

            .time {
                font-size: 14px;
                color: #b3b9c7;
                line-height: 16px;
            }
        }
    }

    .suggest {
        color: #696e80;
    }

    &.animate-active {
        animation-delay: 2s !important;
        -webkit-animation-delay: 2s !important;
        animation-fill-mode: forwards !important;
        -webkit-animation-fill-mode: forwards !important;
    }
}

.col-md4 {

    img {
        width: 100%;
        height: auto;
        vertical-align: top;
    }

    .qrcodes {
        overflow: hidden;
        margin-top: -16px;
    }

    .qrcode {
        float: left;
        overflow: hidden;

        &.gzh {
            margin-right: 32px;
        }

        &-img {
            width: 110px;
            height: 110px;
            background-color: #fff;
            border-radius: 8px;
            text-align: center;

            img {
                width: 86px;
                height: auto;
                margin-top: 12px;
            }
        }

        &-text {
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            padding: 12px 0;
            color: #717274;
        }
    }

    &.animate-active {
        animation-delay: 2.5s  !important;
        -webkit-animation-delay: 2.5s  !important;
        animation-fill-mode: forwards !important;
        -webkit-animation-fill-mode: forwards !important;
    }
}

.section-footer {
    width: 100%;
    min-width: 1200px;
    padding: 72px 0 36px;
    background-color: #fffeff;
    border-top: 3px solid #f5232d;

    .wrapper {
        width: 1200px;
    }

    // &.ourdesc {
    //     .col-md1 {
    //         opacity: 0;
    //     }

    //     .col-md2 {
    //         opacity: 0;
    //     }

    //     .col-md3 {
    //         opacity: 0;
    //     }

    //     .col-md4 {
    //         opacity: 0;
    //     }

    //     .base-footer {
    //         position: relative;
    //         opacity: 0;
    //     }
    // }

    .base-footer {
        opacity: 1;
        margin-top: 16px;

        &.animate-active {
            animation: 1s footeritemup linear !important;
            animation-delay: 2.4s !important;
            -webkit-animation-delay: 2.4s !important;
            animation-fill-mode: forwards !important;
            -webkit-animation-fill-mode: forwards !important;
        }
    }

    .contact {
        padding-bottom: 30px;
    }

    .company {
        text-align: center;
        padding: 24px 0 8px;
        border-top: 1px solid #ebebeb;

        a {
            font-size: 12px;
            position: relative;
            display: inline-block;
            height: 20px;
            line-height: 20px;
            text-decoration: none;
            color: #98989f;
            text-align: center;
            padding: 0 8px;

            &:after {
                content: "";
                width: 1px;
                height: 13px;
                position: absolute;
                right: -1px;
                top: 3px;
                background-color: #98989f;
            }

            &:last-child:after{
                display: none;
            }

            &:hover {
                color: #ff443d;
            }
        }

        .suggest {
            color: #98989f;
            font-size: 12px;
            line-height: 20px;
            cursor: pointer;

            &:hover {
                color: #ff443d;
            }
        }
    }

    .company-info {
        padding-bottom: 8px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #98989f;
        margin: 0;

        span {
            display: inline-block;
            margin-left: 6px;
        }
    }

    .blogroll {
        &-title {
            color: #333;
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
        }

        &-suggest {
            display: inline-block;
            color: #717274;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-right: 20px;
            margin-bottom: 8px;

            &:hover {
                color: #ff443d;
                transition: color .3s;
            }
        }
    }
}

.suggest {
    &:hover {
        color: #ff443d;
    }
}
</style>
