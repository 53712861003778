<!-- 海外区号选择模块 -->
<template>
    <div class="country-select">
        <div class="select-section" @click="clickCountryCodeSelection">
            <span class="country-code">{{countryCodeSelected}}</span>
            <select v-if="countryCodeData.hot" v-model="countryCodeSelected" class="select" @change="emitRegionCode">
                <optgroup v-for="(val, keyName) in countryCodeData" :key="keyName" :label="keyName === 'hot' ? '热门' : keyName ">
                    <option
                        v-for="(item, ind) in val"
                        :key="item.code + ind"
                        :value="item.code"
                    >
                        {{item.text + ' ' + item.code}}
                    </option>
                </optgroup>
            </select>
            <img class="arrow-down" src="~assets/img/pulldown.png">
        </div>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import api from '~/api';

export default {
    name: 'ForeignMobile',
    props: {
        hideWechat: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            defaultCountryCode: '+86',
            countryCodeSelected: '+86',
            countryCodeData: {},
            getCountryOrRegionCodeErrMsg: ''
        };
    },
    computed: {
        ...mapState({
            countryCode: state => state.countryCode
        })
    },

    watch: {
        countryCode(val) {
            // 同步不同页面中该组件的区号
            this.countryCodeSelected = val;
        }
    },
    mounted() {
        // 同步不同页面中该组件的区号
        this.countryCodeSelected = this.countryCode;
        this.getCountryOrRegionCode();
    },
    methods: {
        // 组件被渲染时就请求区号接口，点击区号区域，如果接口有问题就弹窗提示，而不是接口错误就立马弹窗
        clickCountryCodeSelection() {
            if (!this.countryCodeData.hot && this.getCountryOrRegionCodeErrMsg) {
                this.$toasted.show(this.getCountryOrRegionCodeErrMsg, {
                    theme: 'toasted-primary',
                    position: 'top-center',
                    duration: 2000
                });
            }
        },

        /**
         * 向父级抛出选择的区号
         */
        emitRegionCode(e) {
            this.$emit('countryOrRegionCode', {
                code: this.countryCodeSelected
            });

            this.$store.commit('SET_REGION_CODE', this.countryCodeSelected);
        },

        /**
         * 获取大陆以外地区的区号
         */
        getCountryOrRegionCode() {
            this.$axios.get(api.user.countryOrRegionCode).then(res => {
                if (+res.data.code === 0 && res.data && res.data.data) {
                    const countryCodes = res.data.data;
                    const {hot} = countryCodes;
                    delete countryCodes.hot;
                    // 删除hot字段后，对象中的键值只有 A-Z， 为了保证顺序，手动排序一次
                    const props = Object.keys(countryCodes).sort();
                    const countryCodeSorted = {};

                    props.forEach(prop => {
                        countryCodeSorted[prop] = countryCodes[prop];
                    });

                    // 调整 hot 字段 在列表头部, ES2015 js 对象的属性迭代顺序， 数字(1, '1')按照升序排列 ,其它类型都按照插入顺序排列
                    this.countryCodeData = {hot, ...countryCodeSorted};
                    // 由于数据长期不变，所以直接存本地
                }
                else {
                    this.getCountryOrRegionCodeErrMsg = res.data.msg || '网络异常，请刷新重试～';
                }
            }).catch(error => {
                this.getCountryOrRegionCodeErrMsg = error.response.data && error.response.data.msg || '网络异常，请刷新重试～';
            });
        }
    }
};
</script>

<style lang="less" scoped>

.country-select {
    display: inline-block;
    vertical-align: middle;
    margin-left: 48px;

    .select-section {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        .country-code {
            font-family: AvenirNext-Medium, AvenirNext;
            font-size: 16px;
            font-weight: 600;
            color: #333;
        }

        .arrow-down {
            position: relative;
            bottom: 0;
            width: 16px;
            height: 16px;
            margin-left: 5px;
            margin-right: 18px;
        }

        .select {
            outline: none;
            height: 100%;
            position: absolute;
            opacity: 0;
            display: inline-block;
            width: 100%;
            z-index: 5;
        }
    }
}
</style>>
