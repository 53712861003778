<template>
    <img v-lazy="url" :alt="alt" class="img">
</template>

<script>
import {isWebP} from '@base/util/common';

const hasWebp = isWebP();
// webp-image组件：用于兼容webp格式
export default {
    props: {
        src: {
            type: String,
            default: '//p.gsxcdn.com/573325583_tpkva5ck.png'
        },
        width: {
            type: String,
            required: true
        },
        height: {
            type: String,
            required: true
        },
        quality: {
            type: String,
            default: '75'
        },
        alt: {
            type: String,
            default: ''
        }
    },
    computed: {
        url() {
            if (this.src.indexOf('?x-oss-process=') !== -1) {
                return this.src;
            }
            /* eslint-disable max-len */
            const url = `${this.src}?x-oss-process=image/resize,limit_0,m_fill,w_${this.width * 2},h_${this.height * 2}/quality,q_${this.quality}`;
            if (hasWebp) {
                return `${url}/format,webp`;
            }
            return url;
        }
    }
};

</script>
<style lang="less" scoped>
.img {
    background-color: #d8d9e0;
}

</style>
