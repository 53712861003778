<template>
    <header
        class="section-header"
        :class="{active: path !== '/', ourdesc: path === '/'}"
    >
        <div class="wrapper">
            <!-- pc： 首页-顶部:需要修改部分 -->
            <!-- logo，首页需要申请点 -->
            <!-- 高途官网-顶导按钮点击 9268146812119040-->
            <h1 class="header-logo">
                <nuxt-link
                    :target="isBlank"
                    v-clickReport="{eventId: 9268146812119040, ...haboParams('高途logo')}"
                    to="/"
                />
            </h1>
            <!-- <search v-if="isShowSearch"/> -->
            <ul class="header-nav clearfix">
                <li class="nav-tab">
                    <nuxt-link
                        :target="isBlank"
                        v-clickReport="{eventId: 9268146812119040, ...haboParams('首页')}"
                        to="/"
                        :class="($route.name === 'index') ? 'nuxt-link-active' : ''"
                        exact
                    >
                        首页
                    </nuxt-link>
                </li>
                <li class="nav-tab bubble-wrapper">
                    <div>
                        <nuxt-link
                            :target="isBlank"
                            v-clickReport="{eventId: 9268146812119040, ...haboParams('选课中心')}"
                            to="/category?from_link=header"
                            :class="($route.name === 'category*') ? 'nuxt-link-active' : ''"
                        >
                            选课中心
                        </nuxt-link>
                    </div>
                    <div class="bubble" v-show="!hasEnterCategory && isShowBubble">
                        在这里选择适合自己的课程吧~
                    </div>
                </li>
                <!-- <li class="nav-tab">
                    <div>
                        <nuxt-link
                            :target="isBlank"
                            v-clickReport="{eventId: 9268146812119040, ...haboParams('下载app')}"
                            to="/download"
                        >
                            下载APP
                        </nuxt-link>
                    </div>
                </li> -->
                <!-- 需要重新申请点 -->
                <!-- <li class="nav-tab">
                    <nuxt-link
                        :target="isBlank"
                        v-clickReport="{eventId: 9268146812119040, ...haboParams('关于高途')}"
                        to="/aboutus"
                    >
                        关于高途
                    </nuxt-link>
                </li> -->
                <li class="header-authority">
                    <div
                        v-if="token"
                        class="logged-in"
                        data-cy="logged-in"
                    >
                        <div
                            v-if="userInfo && userInfo.userIcon && userInfo.userIcon.url"
                            class="user-avator"
                            data-cy="logged-in-user-avator"
                        >
                            <webp-image :src="userInfo.userIcon.url" width="80" height="80" />
                        </div>
                        <div
                            v-else
                            class="user-avator"
                            data-cy="logged-in-user-avator"
                        >
                            <img src="https://i.gsxcdn.com/2119737852_a7otrl9m.png" alt="avator">
                        </div>
                        <!-- <span class="icon"></span> -->
                        <div class="user-avator-card">
                            <div class="user-card__list">
                                <!-- <nuxt-link
                                    :target="isBlank"
                                    to="/account/profile"
                                >
                                    我的账户
                                </nuxt-link>
                                <nuxt-link
                                    :target="isBlank"
                                    v-haboClickReport="9126447714428928"
                                    to="/account/study"
                                >
                                    我的课程
                                </nuxt-link>
                                <nuxt-link
                                    :target="isBlank"
                                    to="/account/orders"
                                >
                                    我的订单
                                </nuxt-link> -->
                                <!-- <nuxt-link v-haboClickReport="4812795205412864" to="/study">学习中心</nuxt-link> -->
                                <a
                                    href="javascript:;"
                                    @click="userLogout"
                                >
                                    {{logout ? '退出中...' : '退出登录'}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else
                        v-clickReport="34219564"
                        class="not-logged-in"
                    >

                        <span
                            class="login"
                            data-cy="login-register-btn"
                            @click="onLoginHandler"
                        >登录 / 注册</span>

                    </div>
                </li>
            </ul>
        </div>
    </header>
</template>
<script>
import {mapState} from 'vuex';
import webpImage from '@business/components/common/webpImage';
import Search from '@business/components/common/Search';
import api from '~/api';
import {throttle} from 'throttle-debounce';
import {PAGE_NAME} from '@base/constants/exercise';

// 高途官网-登录/注册按钮点击
const LOGIN_REPORT = '9268139364739072';
// 高途官网-顶导按钮曝光
const HEADER_BUTTON_SHOW = 9268145220773888;
const BUBBLE_SHOW = '8123465623103488';

/* global gio */
export default {
    name: 'NewHeader',
    components: {
        'webp-image': webpImage,
        Search
    },
    data() {
        return {
            logout: false,
            path: '',
            // 气泡清除定时器
            bubbleTimer: null,
            // 控制气泡产生  ready-未出现过 pause-出现过未达到60s暂停 stop-出现了60s不再展示 show-正在展示
            bubbleStatus: 'ready',
            //一小时无操作则自动退出登录处理
            // 上一次点击时间
			lastTime: null,
			// 当前时间
			currentTime: null,
			// 超时时间, 如果1个小时都没有点击页面就算超时
			sys_timeout: 60 * 60 * 1000,
			// 检查的时间，每隔5分钟检查一次有没有超时
			check_time: 5 * 60 * 1000,
			// 计时器
			whole_timer: null
        };
    },
    computed: {
        ...mapState({
            token: state => state.token,
            userInfo: state => state.userInfo,
            isShowBubble: state => state.isShowCategoryBubble,
            hasEnterCategory: state => state.hasEnterCategory,
            commonVariable: state => state.commonVariable
		}),
        isBlank() {
            const {name} = this.$route;
            return PAGE_NAME[name] ? '_blank' : '_self';
		},
		isShowSearch() {
			return !(this.path === '/');
		}
    },
    watch: {
        $route: {
            handler(val, oldVal) {
                if (val !== oldVal) {
                    if (this?.$habo) {
                        this.$habo.haboViewPage(HEADER_BUTTON_SHOW);
                    }
                    // 更新路由，重置引导气泡展示状态
                    this.path = val.path;
                    this.bubbleStatus = 'ready';
                    this.isShowBubble && this.$store.commit('SET_ISSHOWCATEGORYBUBBLE', false);
                    if (this.bubbleTimer) {
                        clearTimeout(this.bubbleTimer);
                        this.bubbleTimer = null;
                    }
                }
            },
            immediate: true
        }
    },
    created() {
        this.path = this.$route.path;
    },
    mounted() {
        this.throttleScroll = throttle(50, this.handleScroll);
        window.addEventListener('scroll', this.throttleScroll, {passive: true});
        let {from, redirect} = this.$route.query;
        redirect = decodeURIComponent(redirect);
        if (from && redirect) {
            this.$store.commit('SET_REDIRECT_URL', redirect);
            this.onLoginHandler();
        }

        window.addEventListener('click', this.updateLastClickTime);
		// 启动定时器
		this.isLoginOut();
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.throttleScroll);
        window.removeEventListener('click', this.updateLastClickTime);
        clearInterval(this.whole_timer);
    },
    methods: {
        userLogout() {
            this.logout = true;
            const {name} = this.$route;
            this.$axios.$get(api.user.logout).then(res => {
                this.logout = false;
                if (res.code === 0) {
                    this.$store.dispatch('GET_ENTRANCE');
                    this.$store.commit('CLEAR_TOKEN');
                    if (PAGE_NAME[name]) {
                        // 题库页，退出后需刷新一下，防止样式错乱
                        location.reload();
                    }
                    // 清除gio中的用户登录态
                    window.gio && gio('clearUserId');
                }
            });
        },
        isExercisePage() {
            const {name} = this.$route;
            return name === 'exercise-tools*';
        },
        onLoginHandler() {
            this.$store.commit('SET_ISSHOWLOGIN', true);
            this.$habo.haboClickReport(LOGIN_REPORT, {
                index: this.isExercisePage() ? '题库解析' : '顶导',
            });
        },
        // 教师资质页面&&下载页面页面且未进入过选课中心，滚动出现引导浮窗60s
        handleShowBubble() {
            if ((this.path === '/' || this.path === '/download' || this.path === '/aboutus')
                && !this.hasEnterCategory && this.bubbleStatus !== 'stop') {
                switch (this.bubbleStatus) {
                    // 状态为ready时，展示气泡，60s后清除气泡展示
                    case 'ready':
                        this.$store.commit('SET_ISSHOWCATEGORYBUBBLE', true);
                        this.bubbleStatus = 'show';
                        this.$habo.haboViewPage(BUBBLE_SHOW, {source: this.path});
                        this.bubbleTimer = setTimeout(() => {
                            this.$store.commit('SET_ISSHOWCATEGORYBUBBLE', false);
                            this.bubbleStatus = 'stop';
                            clearTimeout(this.bubbleTimer);
                        }, 60000);
                        break;
                    // 状态为暂停时，恢复展示
                    case 'pause':
                        this.$store.commit('SET_ISSHOWCATEGORYBUBBLE', true);
                        this.bubbleStatus = 'show';
                        break;
                    // 其他状态此时不做处理
                    default:
                        break;
                }
            }
        },
        handleCloseBubble() {
            // 此时为展示状态时，气泡关闭展示， 状态更新为pause
            if (this.bubbleStatus === 'show') {
                this.$store.commit('SET_ISSHOWCATEGORYBUBBLE', false);
                this.bubbleStatus = 'pause';
            }
        },
        // 滚动计算
        handleScroll() {
            this.$nextTick(() => {
                const scrollTop
                    = window.pageYOffset
                        || document.documentElement.scrollTop
                        || document.body.scrollTop;
                const navbar = document.querySelector('.section-header');
                if (navbar) {
                    this.path === '/' && navbar.classList.add('active');
                    this.path !== '/' && navbar.classList.add('inset');
                    if (scrollTop === 0) {
                        this.path === '/' && navbar.classList.remove('active');
                        this.path !== '/' && navbar.classList.remove('inset');
                    }
                    if (scrollTop >= navbar.offsetHeight) {
                       // this.handleShowBubble();
                    }
                    else {
                        //this.handleCloseBubble();
                    }
                }
            });
        },
        haboParams(param) {
            return {
                index: this.isExercisePage() ? '题库解析' : param,
            };
        },
        // 一小时无操作则自动退出登录处理
        updateLastClickTime(){
			this.$store.commit('SET_LASTCLICK_TIME', new Date().getTime());
		},
        // 判断是否超时
		isTimeOut(){
			// 页面上一次的点击时间
			this.lastTime = this.$store.state.lastClickTime;
			this.currentTime = new Date().getTime();
			// 超时了
			if((this.currentTime - this.lastTime) > this.sys_timeout) {
				return true;
			} else {
				return false;
			}
		},
		isLoginOut() {
			// 每次用定时器之前先清除一下 
			clearInterval(this.whole_timer);
			// 这里要备份一下this指针
			let _this = this;
			this.whole_timer = setInterval(function () {
				// 判断一下是否超时，如果超时了就退出
				if (_this.isTimeOut() && _this.$store.state.token) {
					_this.userLogout();
					_this.$router.push(`/`);
				}
			}, _this.check_time);
		},
		userLogout() {
            this.logout = true;
            const {name} = this.$route;
            this.$axios.$get(api.user.logout).then(res => {
                this.logout = false;
                if (res.code === 0) {
                    this.$store.dispatch('GET_ENTRANCE');
                    this.$store.commit('CLEAR_TOKEN');
                    if (PAGE_NAME[name]) {
                        // 题库页，退出后需刷新一下，防止样式错乱
                        location.reload();
                    }
                    // 清除gio中的用户登录态
                    window.gio && gio('clearUserId');
                }
            });
        }
    }
};
</script>
<style lang="less" scoped>
.section-header {
    left: 0;
    right: 0;
    top: 0;
    z-index: 110;
    width: 100%;
    min-width: 1200px;
    height: 80px;
    // background-color: var(--defaultHeaderBgc);
    backdrop-filter: var(--defaultBackdrop);
    transition: all .5s;

    .wrapper {
        width: 1200px;
    }
    // 导航栏
    .header-nav {
        float: right;
        list-style: none;

        .nav-tab {
            float: left;
            line-height: 80px;
            margin: 0 20px;

            .nuxt-link-active {
                transition: all .5s;
                color: var(--defaultHeaderHoverColor);
                font-weight: 400;
                &::after {
                    content: '';
                    display: block;
                    position: relative;
                    width: 24px;
                    height: 3px;
                    background-color: var(--defaultHeaderActiveBgc);
                    top: -25px;
                    margin: 0 auto;
                    border-radius: 2px;
                    transition: all .5s;
                }
            }

            a {
                font-size: 16px;
                line-height: 25px;
                color: var(--defaultHeaderColor);
                transition: all .5s;

                &:hover {
                    color: var(--defaultHeaderHoverColor);
                }
            }
        }
    }

    // logo
    .header-logo {
        position: relative;
        top: 4px;
        float: left;
        width: 295px;
        height: 41px;
        background-image: var(--defaultLogo);
        background-size: contain;
        background-repeat: no-repeat;
        transition: all .3s;

        a {
            display: block;
            // width: 137px;
            height: 33px;
            text-indent: -99999px;
        }
    }

    // 登录注册
    .header-authority {
        float: right;
        margin: 0 0 0 20px;
        line-height: 80px;

        .not-logged-in {
            .login {
                transition: all .5s;
                color: var(--defaultHeaderBtnColor);
                background: var(--defaultHeaderBtnBgc); // f5 23 2d
                border-radius: 20px;
                width: 108px;
                height: 40px;
                line-height: 40px;
                display: inline-block;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    background: var(--defaultHeaderHoverBgc);
                }
            }
        }

        .logged-in {
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;
            height: 80px;

            .user-avator {
                position: relative;
                width: 40px;
                height: 40px;
                margin-right: 8px;
                border-radius: 50%;
                top: -4px;
                // right: 0;
                // bottom: 0;
                // left: 0;

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 50%;
                }
            }

            .user-avator-card {
                position: absolute;
                display: none;
                top: 76px;
                right: -24px;
                z-index: 100;
                filter: drop-shadow(0 0 2px #eaeaeb);
                box-shadow: 0px 2px 20px 0px rgba(180, 190, 201, 0.2);
                background-color: #fff;
                border-radius: 8px;
                border: 1px solid #eaeaeb;
                padding: 8px 0;

                .user-card__list {
                    width: 100px;
                    background: #fff;
                    border-radius: 8px;

                    a {
                        position: relative;
                        display: block;
                        height: 35px;
                        text-align: center;
                        line-height: 35px;
                        color: #333333;
                        font-size: 13px;
                        transition: all .3s linear;
                        box-sizing: border-box;

                        &:hover {
                            background: rgba(255, 68, 61, .1);
                            color: #ff443d;
                            border: none;
                        }
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    border: 10px solid transparent;
                    border-bottom-color: #fff;
                    top: -17px;
                    left: 40px;
                }
            }

            .icon {
                display: inline-block;
                position: relative;
                top: 2px;
                margin-left: 4px;
                width: 24px;
                height: 24px;
                vertical-align: text-bottom;
                background-image: url(//i.gsxcdn.com/735018970_tz9g4evj.png);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }

            &:hover {
                .user-avator-card {
                    display: block;
                }

                .icon {
                    background-image: url(//i.gsxcdn.com/735018967_o8l5mxka.png);
                }
            }
        }
    }

    // 气泡引导样式
    .bubble-wrapper {
        position: relative;

        .bubble {
            position: absolute;
            width: 248px;
            height: 46px;
            background: #FEEAEA;
            bottom: -38px;
            left: -30px;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            line-height: 46px;
            border-radius: 10px;
            color: #F73535;
            animation: bubbleAnimation 2s linear;
        }

        .bubble:after{
            content: '';
            position: absolute;
            bottom: 100%;
            left: 57px;
            width: 0;
            height: 0;
            border-width: 10px;
            border-style: solid;
            border-color: transparent;
            border-bottom-color: #FEEAEA;
        }

        @keyframes bubbleAnimation {
            0% {
                transform: translateY(0);
            }
            10% {
                transform: translateY(-10px);
            }
            20% {
                transform: translateY(0);
            }
            30% {
                transform: translateY(-10px);
            }
            40% {
                transform: translateY(0);
            }
        }
    }
    // 初始化首页定位
    &.ourdesc {
        position: fixed;
    }
    // 除首页其他页面的滚动样式
    &.inset {
        position: fixed;
        box-shadow: 0 6px 12px 0 #b59a9a1a;
        background-color: #fff;
    }
    // 首页滚动时的导航样式
    &.active {
        position: fixed;
        background: var(--changeHeaderBgc);
        backdrop-filter: var(--changeBackdrop);
        box-shadow: 0 6px 12px 0 #b59a9a1a;

        // logo
        .header-logo {
            background-image: var(--changeLogo);
        }

        .header-nav {
        .nav-tab a {
                color: var(--changeHeaderColor);

                &.nuxt-link-active {
                    color: var(--changeHeaderHoverColor);
                    font-weight: 400;
                    &::after {
                        content: '';
                        display: block;
                        position: relative;
                        width: 24px;
                        height: 3px;
                        background-color: var(--changeHeaderActiveBgc);
                        top: -25px;
                        margin: 0 auto;
                        border-radius: 2px;
                    }
                }

                &:hover {
                    color: var(--changeHeaderHoverColor);
                }
            }
        }

        .header-authority {
            .not-logged-in {
                .login {
                    color: var(--changeHeaderBtnColor);
                    background: var(--changeHeaderBtnBgc); // f5 23 2d
                    border-radius: 20px;
                    width: 108px;
                    height: 40px;
                    line-height: 40px;
                    display: inline-block;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                    cursor: pointer;

                    &:hover {
                        background: var(--changeHeaderHoverBgc);
                    }
                }
            }
        }
    }
}

</style>
